/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

import { COLORS, Loading, MultiSelectControlledOption } from "components";

import { ChartWrapper } from "../charts/chartLayoutWrapper";
import {
  CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_DATA,
  CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_DATA,
  CONSUMPTIONS_BY_REGION_HIGHCHARTS_DATA,
  CONSUMPTIONS_BY_SITE_HIGHCHARTS_DATA,
  CONSUMPTIONS_MONTHLY_HIGHCHARTS_ELE_DATA,
  CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_ELE_DATA,
  UTILITY_NAMES,
} from "../consts";
import {
  findCommonElements,
  generateMonths,
  parseConsumptionDataMonthly,
  parseCountriesConsumptionData,
  parseFTConsumptionData,
  parseRegionsConsumptionData,
  parseSiteConsumptionData,
  parseYearConsumptionData,
} from "../utils";

type Props = {
  consumptionLoadingStates: any;
  monthConsumptionYear: string;
  setMonthConsumptionYear: (data: string) => void;
  monthlyData: unknown;
  sitesData: unknown;
  yearlyData: unknown;
  regionsData: unknown;
  facilityTypesData: unknown;
  countriesResp: unknown;
  sitesOptions: MultiSelectControlledOption[];
  countryRegionsList: string[];
  facilityTypesList: string[];
  startMonth: string;
  endMonth: string;
  selectedYear: number;
};

export const ConsumptionElectricityComponent = ({
  consumptionLoadingStates,
  monthlyData,
  sitesData,
  yearlyData,
  regionsData,
  facilityTypesData,
  countriesResp,
  sitesOptions,
  countryRegionsList,
  facilityTypesList,
  monthConsumptionYear,
  setMonthConsumptionYear,
  startMonth,
  endMonth,
  selectedYear

}: Props) => {

  const [
    monthlyConsumptionHighChartsData,
    setMonthlyConsumptionHighChartsData,
  ] = useState(CONSUMPTIONS_MONTHLY_HIGHCHARTS_ELE_DATA);

  const [siteConsumptionHighChartsData, setSiteConsumptionHighChartsData] =
    useState(CONSUMPTIONS_BY_SITE_HIGHCHARTS_DATA);

  const [yearConsumptionData, setYearConsumptionData] = useState(
    CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_ELE_DATA,
  );

  const [regionsConsumptionData, setRegionsConsumptionData] = useState(
    CONSUMPTIONS_BY_REGION_HIGHCHARTS_DATA,
  );

  const [ftConsumptionData, setFTConsumptionData] = useState(
    CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_DATA,
  );

  const [countriesCSata, setCountriesCSData] = useState(
    CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_DATA,
  );

  useEffect(() => {
    if (monthlyData) {
      const parsedData = parseConsumptionDataMonthly(
        monthlyData,
        startMonth,
        endMonth,
        UTILITY_NAMES.ELECTRICITY,
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_MONTHLY_HIGHCHARTS_ELE_DATA),
      );
      const monthsHeaders = generateMonths(startMonth, endMonth, selectedYear);
      const xAxisData = monthsHeaders.map((monthValue) => {
        const [year, monthName] = monthValue.split(" ");
        const shortYear = year.slice(-2);
        return `${monthName} '${shortYear}`;
      });
      updatedChartsData.series = parsedData;
      updatedChartsData.xAxis.categories = xAxisData;
      updatedChartsData.yAxis.title.text = "kWh";
      setMonthlyConsumptionHighChartsData(updatedChartsData);
    }
  }, [monthlyData, monthConsumptionYear]);

  useEffect(() => {
    if (sitesData) {
      const parsedData = parseSiteConsumptionData(
        sitesData,
        sitesOptions,
        UTILITY_NAMES.ELECTRICITY,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_SITE_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = sitesOptions.map((e) => e.label);
      const indexArraymain: any[] = [];
      parsedData.filter((e: any) => {
        const indexArray: any[] = [];
        e.data.forEach((key: any, index: any) => {
          if (key.y === 0) {
            indexArray.push(index);
          }
        });
        return indexArraymain.push(indexArray);
      });

      const commonElements = findCommonElements(...indexArraymain);
      const modifiedData = parsedData.map((series: any) => ({
        name: `FY${series.name}`, // Append 'FY' before the year
        data: series.data,
      }));

      updatedChartsData.series = modifiedData;
      const sort = commonElements.sort((a, b) => b - a);
      sort.forEach((element: Number) => {
        updatedChartsData.xAxis.categories.splice(Number(element), 1);
        parsedData.filter((e: any) => e.data.splice(Number(element), 1));
      });
      setSiteConsumptionHighChartsData(updatedChartsData);
    }
  }, [sitesData]);

  useEffect(() => {
    if (yearlyData) {
      const parsedData = parseYearConsumptionData(
        yearlyData,
        "consumption",
        UTILITY_NAMES.ELECTRICITY,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_ELE_DATA),
      );
      updatedChartsData.xAxis.categories = Object.keys(yearlyData)
        .sort()
        .map((e) => `FY${e}`);
      updatedChartsData.series = parsedData;
      setYearConsumptionData(updatedChartsData);
    }
  }, [yearlyData]);

  useEffect(() => {
    if (regionsData) {
      const parsedData = parseRegionsConsumptionData(
        regionsData,
        countryRegionsList,
        UTILITY_NAMES.ELECTRICITY,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_REGION_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = countryRegionsList;
      updatedChartsData.series = parsedData;
      setRegionsConsumptionData(updatedChartsData);
    }
  }, [regionsData]);

  useEffect(() => {
    if (facilityTypesData) {
      const parsedData = parseFTConsumptionData(
        facilityTypesData,
        facilityTypesList,
        UTILITY_NAMES.ELECTRICITY,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = facilityTypesList;
      updatedChartsData.series = parsedData;
      setFTConsumptionData(updatedChartsData);
    }
  }, [facilityTypesData]);

  useEffect(() => {
    if (countriesResp) {
      const parsedData = parseCountriesConsumptionData(
        countriesResp,
        ["US", "Rest of world"],
        UTILITY_NAMES.ELECTRICITY,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = ["US", "Rest of world"];
      updatedChartsData.series = parsedData;
      setCountriesCSData(updatedChartsData);
    }
  }, [countriesResp]);

  const dataCheck = (dataRes: any) => {
    const hasNonZeroValue = dataRes?.series?.some((item: any) => {
      if (Array.isArray(item?.data)) {
        return item.data.some((value: number | null) => value !== null && value !== 0);
      }
      return false;
    });
    return !!hasNonZeroValue;
  };

  const monthlyConsumption = dataCheck(monthlyConsumptionHighChartsData);
  const hasFalseValue = dataCheck(siteConsumptionHighChartsData);
  const hasFalseyearConsumption = dataCheck(yearConsumptionData)
  const consumptionbyRegion = dataCheck(regionsConsumptionData)
  const ftConsumption = dataCheck(ftConsumptionData)
  const countries = dataCheck(countriesCSata)

  return (
    <>
      <ChartWrapper
        label={
          <Typography
            sx={{
              color: monthlyConsumption
                ? COLORS.lightgray
                : "#00000042",
              fontSize: "16px",
              fontFamily: "Manrope",
              fontWeight: "600"
            }}
          >
            Consumption Month on Month
          </Typography>
        }
        selectedYear={monthConsumptionYear}
        setSelectedYear={setMonthConsumptionYear}
        id="ConsumptionMonth"
      >
        {consumptionLoadingStates?.getSSConsumptionDataLoading ? (<Loading />) : monthlyConsumption ?
          <HighchartsReact
            highcharts={Highcharts}
            options={monthlyConsumptionHighChartsData}
          />
          : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
        }
      </ChartWrapper>

      <ChartWrapper
        label={
          <Typography
            sx={{
              color: hasFalseValue
                ? COLORS.lightgray
                : "#00000042",
              fontSize: "16px",
              fontFamily: "Manrope",
              fontWeight: "600"
            }}
          >
            Consumption by Site
          </Typography>
        }
        id="ConsumptionbySite"
      >
        {consumptionLoadingStates?.getConsumptionSiteLoading ? (<Loading />) : hasFalseValue ?
          <HighchartsReact
            highcharts={Highcharts}
            options={siteConsumptionHighChartsData}
          />
          : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
        }
      </ChartWrapper>


      <ChartWrapper
        label={
          <Typography
            sx={{
              color: hasFalseyearConsumption
                ? COLORS.lightgray
                : "#00000042",
              fontSize: "16px",
              fontFamily: "Manrope",
              fontWeight: "600"
            }}
          >
            Consumption Year on Year
          </Typography>
        }
        id="ConsumptionYear"
      >
        {consumptionLoadingStates?.getConsumptionYearLoading ? (<Loading />) : hasFalseyearConsumption ?
          <HighchartsReact
            highcharts={Highcharts}
            options={yearConsumptionData}

          />
          : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
        }
      </ChartWrapper>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ChartWrapper
          styles={{ width: "50%" }}
          label={
            <Typography
              sx={{
                color: consumptionbyRegion
                  ? COLORS.lightgray
                  : "#00000042",
                fontSize: "16px",
                fontFamily: "Manrope",
                fontWeight: "600"
              }}
            >
              Consumption by Region
            </Typography>
          }
          id="ConsumptionbyRegion"
        >
          {consumptionLoadingStates?.getConsumptionRegionLoading ? (<Loading />) : consumptionbyRegion ?
            <HighchartsReact
              highcharts={Highcharts}
              options={regionsConsumptionData}

            />
            : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
          }
        </ChartWrapper>

        <ChartWrapper
          styles={{ width: "50%" }}
          label={
            <Typography
              sx={{
                color: ftConsumption
                  ? COLORS.lightgray
                  : "#00000042",
                fontSize: "16px",
                fontFamily: "Manrope",
                fontWeight: "600"
              }}
            >
              Consumption by Facility type
            </Typography>
          }
          id="ConsumptionbyFacility"
        >
          {consumptionLoadingStates?.getConsumptionFacilityTypeLoading ? (<Loading />) : ftConsumption ?
            <HighchartsReact
              highcharts={Highcharts}
              options={ftConsumptionData}

            />
            : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
          }
        </ChartWrapper>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ChartWrapper
          styles={{ width: "50%" }}
          label={
            <Typography
              sx={{
                color: countries
                  ? COLORS.lightgray
                  : "#00000042",
                fontSize: "16px",
                fontFamily: "Manrope",
                fontWeight: "600"
              }}
            >
              Consumption by US vs Rest of world
            </Typography>
          }
          id="ConsumptionbyUS"
        >
          {consumptionLoadingStates?.getConsumptionByCountriesLoading ? (<Loading />) : countries ?
            <HighchartsReact
              highcharts={Highcharts}
              options={countriesCSata}
            />
            : <Box sx={{ backgroundColor: "#f8f8f8", height: "300px" }}> <Typography sx={{ textAlign: "center", alignItems: "center", pt: "100px", fontSize: "25px" }}>No data available</Typography></Box>
          }
        </ChartWrapper>
      </Box>
    </>
  );
};
