import { Box, Tab, Tabs as TabsMui } from "@mui/material";
import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useEffect,
} from "react";

export const DashboardTabs: FunctionComponent<{
  tabs: {
    icon?: ReactElement;
    label: ReactNode;
    content: ReactNode;
  }[];
  selectedBtnOption?: string;
  intensity?:boolean;
  refrences?:boolean;
  onTabChange?: Function;
}> = ({ tabs, selectedBtnOption, intensity ,onTabChange,refrences}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  useEffect(() => {
    if (selectedBtnOption) {
      setValue(0);
      if (onTabChange) {
        onTabChange(0);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBtnOption]);

  return (
    /* eslint-disable react/no-array-index-key */
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabsMui
         value={value} 
         onChange={handleChange} 
         textColor={intensity ? "inherit" : undefined}
         TabIndicatorProps={{
          style: {
            ...(intensity && {
              backgroundColor: "#054254",
              
            })
          }
        }}
        sx={{
          ".Mui-selected": {
            ...(intensity && {
              color: '#054254 !important' 
            })
          },
        }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={`tab-${index}`}
              icon={tab.icon}
              label={tab.label}
              iconPosition="start"
              id={`tab-${index}`}
              aria-controls={`tabpanel-${index}`}
              sx={{
                minHeight: "48px",
                margin: "0 0 0 10px",
                textTransform: "none",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#000000 !important",
                ...(intensity && {
                  height:"22px",
                  gap:"37px",
                  padding: "0",
                  marginRight:"36px",
                })
              }}
            />
          ))}
        </TabsMui>
      </Box>
      {tabs.map((tab, index) => (
        <Box
          sx={ refrences ? { mt: 0, flexGrow: 1, overflow: "auto" }:{ mt: 3, flexGrow: 1, overflow: "auto" }}
          key={`tabpanel-${index}`}
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
        >
          {value === index && tab.content}
        </Box>
      ))}
    </Box>
    /* eslint-enable react/no-array-index-key */
  );
};
