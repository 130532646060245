import { Box, Button } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Row, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Bill, DocumentDTO, deleteUtilityBill } from "api";

import { TrashIcon } from "assets";

import {
  DeleteDialog,
  Label12Regular,
  Label16SemiBold,
  Table,
  useColumnsWithSelectionColumn,
  useDialogControls,
  useLocalSelectedRows,
} from "components";

import { useDocumentBills, useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { useSaveCurrentUrl, useUserRole } from "recoils";

import { getFromLocalStorage } from "utils";

// eslint-disable-next-line
import { DOCUMENT_UTILITY_BILLS_COLUMNS } from "./columns";

// eslint-disable-next-line
import { ADD_DOCUMENT_BILL_PAGE } from "../../add-document-bill";
import { DOCUMENT_UPLOAD_PAGE } from "../../consts";

export const DocumentExpandedRow: React.FunctionComponent<{
  row: Row<DocumentDTO>;
}> = ({ row }) => {
  const queryClient = useQueryClient();
  const notify = useNotify();
  const deleteDialogControls = useDialogControls();
  const saveCurrentUrl = useSaveCurrentUrl();
  const navigate = useNavigate();
  const { mutateAsync: deleteUB } = useMutation(deleteUtilityBill);
  const addEntries = getFromLocalStorage("addEntry") || "";
  const dataEntriesFromStorage = getFromLocalStorage("dataEntries") || "";
  const dataEntries = dataEntriesFromStorage
    ? JSON.parse(dataEntriesFromStorage)?.original?.bills
    : "";
  const documentBillsFromStorage = getFromLocalStorage("documentbills") || "";
  const documentId = documentBillsFromStorage
    ? JSON.parse(documentBillsFromStorage)[0]?.id
    : "";

  const document = getFromLocalStorage("document") || "";
  const documentData = (document && JSON.parse(document)) || "";
  const addEntriesDelete = getFromLocalStorage("setid");

  const {
    selectedRows,
    selectedRowsState,
    setSelectedRow,
    selectedRowsCount,
    setFromIntermediateSelection,
    getIsIntermediateSelection,
    setDefaultSelection,
  } = useLocalSelectedRows();

  const columns = useColumnsWithSelectionColumn<Bill>({
    columns: DOCUMENT_UTILITY_BILLS_COLUMNS,
    getIsIntermediateSelection,
    selected: selectedRowsState,
    setSelected: setSelectedRow,
    selectedCount: selectedRowsCount,
    setSelectedFromIndeterminate: setFromIntermediateSelection,
  });

  const { data } = useDocumentBills(
    row && row.original && row.original.id
      ? (row.original.id as string)
      : documentId === ""
      ? documentData.documentGuid || ""
      : documentId,
  );
  const userRole = useUserRole();
  
  const table = useReactTable({
    data: (data && data[0]?.bills) || [],
    columns: addEntries === "true" ? DOCUMENT_UTILITY_BILLS_COLUMNS : columns,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  const currentURL = new URL(window.location.href).pathname;

  useEffect(() => {
    if (documentData?.filename !== undefined) {
      if (
        row &&
        row.original &&
        row.original.fileName !== documentData.filename
      ) {
        localStorage.removeItem("document");
      }
    }
  }, [currentURL, documentData?.filename, row, row.original]);

  let billsLength: number;
  if (data && data.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    const firstObject = data[0];
    if (firstObject && firstObject.bills) {
      billsLength = firstObject.bills.length;
    } 
  }

  const onDeleteClick = () => {
    localStorage.removeItem("document");
    Promise.allSettled(
      selectedRows.map((id) =>
        deleteUB(
          selectedRows.length >= 1
            ? id
            : addEntriesDelete !== null
            ? addEntriesDelete
            : id,
          {
            onError: () =>
              notify.error(
                `Some error has happen while deleting utility bill: ${id}!`,
              ),
          },
        ),
      ),
    ).finally(() => {
      localStorage.removeItem("setid");
      deleteDialogControls.close();
      notify.success("Selected utility bills were deleted!"); // [AW] if error success notification will be fired
      setDefaultSelection();
      if (billsLength <= 1) {
        navigate(DOCUMENT_UPLOAD_PAGE.ABSOLUTE_PATH);
      }
      queryClient.invalidateQueries([QUERY_KEYS.DOCUMENT_BILLS]);
      // eslint-disable-next-line
      useDocumentBills(row.original.id as string);
    });
  };

  return (
    <Box
      sx={(theme) => ({
        paddingTop: 3,
        paddingBottom: 7,
        px: 2,
        border: `1px solid ${theme.palette.background.default}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
        marginBottom: "2px",
      })}
    >
      <Box
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Label16SemiBold mb={0.5}>
            {document === ""
              ? row && row.original && row.original.fileName
              : documentData?.filename}
          </Label16SemiBold>
          <Label12Regular>Billed service(s)</Label12Regular>
        </Box>
        {(!(addEntries === "true" || dataEntries?.length === 0) ||
          documentId !== "") && (
          <Box>
            <Button
              startIcon={<TrashIcon />}
              variant="outlined"
              disabled={!selectedRowsCount || userRole.isAuditor}
              onClick={deleteDialogControls.open}
            >
              Delete
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="outlined"
              disabled={userRole.isAuditor}
              onClick={() => {
                window.localStorage.setItem("addEntry", "true");
                saveCurrentUrl();
                if (row && row.original && row.original.id) {
                  navigate(
                    ADD_DOCUMENT_BILL_PAGE.ABSOLUTE_PATH(
                      row.original.id as string,
                    ),
                  );
                } else {
                  navigate(
                    ADD_DOCUMENT_BILL_PAGE.ABSOLUTE_PATH(documentId as string),
                  );
                }
              }}
            >
              Add data entry
            </Button>
          </Box>
        )}
      </Box>

      <Box>
        <Table table={table} />
      </Box>
      <DeleteDialog
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={onDeleteClick}
        selectedCount={selectedRowsCount}
        target="bill"
        title={`Delete this data entry in document: ${
          document === ""
            ? row && row.original && row.original.fileName
            : documentData.filename
        }`}
      />
    </Box>
  );
};
