import { getResData, httpClient } from "utils";

import { DASHBOARD_API_PATHS } from "./consts";
import { EmissionsOnMonthBody } from "./types";

export const getEmissionsOnMonth = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.EMISSIONS_ON_MONTH, body)
    .then(getResData);

export const getScopeOnMonth = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.EMISSIONS_BY_SCOPE_1, body)
    .then(getResData);

export const getEstimatesOnMonth = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.ESTIMATION_ON_MONTH, body)
    .then(getResData);
    
export const getEstimatesBySite = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.ESTIMATION_BY_SITE, body)
    .then(getResData);

export const getEmissionsBySite = (body: EmissionsOnMonthBody) =>
  httpClient.post(DASHBOARD_API_PATHS.EMISSIONS_BY_SITE, body).then(getResData);

export const getEmissionsByUtilityType = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.EMISSIONS_BY_UTILITY_TYPE, body)
    .then(getResData);

export const getEmissionsByFacilityType = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.EMISSIONS_BY_FACILITY_TYPE, body)
    .then(getResData);

export const getEmissionsYearly = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.EMISSIONS_YEAR_ON_YEAR, body)
    .then(getResData);
export const getEmissionsScopeYearly = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.EMISSIONS_BY_SCOPE, body)
    .then(getResData);

export const getEmissionsByRegion = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.EMISSIONS_BY_REGION, body)
    .then(getResData);

export const getCountriesEmissionsData = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.EMISSIONS_BY_US_VS_ROW, body)
    .then(getResData);

export const getFinancialsOnMonth = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.FINANCIALS_ON_MONTH, body)
    .then(getResData);

export const getFinancialsBySite = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.FINANCIALS_BY_SITE, body)
    .then(getResData);

export const getFinancialsByUtilityType = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.FINANCIALS_BY_UTILITY_TYPE, body)
    .then(getResData);

export const getFinancialsByFacilityType = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.FINANCIALS_BY_FACILITY_TYPE, body)
    .then(getResData);

export const getFinancialsYearly = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.FINANCIALS_YEAR_ON_YEAR, body)
    .then(getResData);

export const getFinancialsByRegion = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.FINANCIALS_BY_REGION, body)
    .then(getResData);

export const getCountriesFinacialsData = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.FINANCIALS_BY_US_VS_ROW, body)
    .then(getResData);

export const getUnitLevelCostData = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.FINANCIALS_UNIT_LEVEL_COST, body)
    .then(getResData);

export const getFinancialEmissionSpendData = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.FINANCIALS_EM_SPEND, body)
    .then(getResData);

export const getEmissionsByScope = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.EMISSIONS_BY_SCOPE, body)
    .then(getResData);

export const getSiteSpecificConsumptionData = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.SITE_SPECIFIC_CONSUMPTION, body)
    .then(getResData);

export const getSiteSpecificEmissionsData = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.SITE_SPECIFIC_EMISSIONS, body)
    .then(getResData);

export const getSpendAccData = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.SITE_SPECIFIC_SPEND_ACC, body)
    .then(getResData);

export const getConsumptionsOnMonth = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.CONSUMPTIONS_ON_MONTH, body)
    .then(getResData);

export const getConsumptionsBySite = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.CONSUMPTIONS_BY_SITE, body)
    .then(getResData);

export const getConsumptionsByFacilityType = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.CONSUMPTIONS_BY_FACILITY_TYPE, body)
    .then(getResData);

export const getConsumptionsYearly = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.CONSUMPTIONS_YEAR_ON_YEAR, body)
    .then(getResData);

export const getConsumptionsByRegion = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.CONSUMPTIONS_BY_REGION, body)
    .then(getResData);

export const getCountriesConsumptionData = (body: EmissionsOnMonthBody) =>
  httpClient
    .post(DASHBOARD_API_PATHS.CONSUMPTIONS_BY_US_VS_ROW, body)
    .then(getResData);
