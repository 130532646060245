/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable */
import { MultiSelectControlledOption } from "components";

import { UTILITY_NAMES } from "./consts";

const MonthMap: any = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11,
};

const customTooltipDataGen = (arrLen: number, unit = "") => {
  const arr: any = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arrLen; i++) {
    const obj = {
      y: 0,
      custom: {
        unit: ` ${unit}`,
      },
    };
    arr.push(obj);
  }
  return arr;
};

export const getRequiredYearDetails = (yearsList: string[], year: string) => {
  let updateYear = false;
  let reqYear = year;
  const dataYears = yearsList.map((i) => Number(i)).sort();
  const userSelectedYearAvailable = dataYears.includes(+year);
  if (!userSelectedYearAvailable) {
    reqYear = String(dataYears[dataYears.length - 1]);
    updateYear = true;
  }
  return { reqYear, updateYear };
};
export const parseSitemissionsData = (
  data: any,
  siteOptions: MultiSelectControlledOption[],
  parameter = "",
  startMonth: any,
  endMonth: any,
) => {
  const siteLabels = siteOptions.map((i) => i.label);
  const chartData: any = {};

  const modifiedData: any = {};

  const yearsList = Object.keys(data).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const filterData = filterDataWithFiscalYear(
      data,
      year,
      startMonth,
      endMonth,
    );
    modifiedData[year] = filterData;
  });

  Object.keys(modifiedData).forEach((key) => {
    const currentYeardata = modifiedData[key];
    if (!chartData[key]) {
      chartData[key] = { name: key, data: Array(siteLabels.length).fill(0) };
    }
    currentYeardata.forEach((item: any) => {
      const index = siteLabels.findIndex((e) => e === item.siteName);
      if (index !== -1) {
        chartData[key].data[index] += parameter
          ? item[parameter]
          : item.co2Emission;
      }
    });
    Object.values(chartData).forEach((item: any) => {
      item.data = item.data.map((value: number) =>
        roundToTwoDecimalPlaces(value),
      );
    });
  });
  return Object.values(chartData) || [];
};
export const parseEstimatedSiteEmissionsData = (
  data: any,
  siteOptions: MultiSelectControlledOption[],
  parameter = "",
  parameter1 = "",
) => {
  const siteLabels = siteOptions.map((i) => i.label);
  const chartData: any = {};
  const tempData: any[] = [];

  data.forEach((item: any) => {
    const tempD = { ...item };
    const tempB = { ...tempD, utilityName: "Estimates" };
    const tempC = { ...item, utilityName: "Bill" };

    tempData.push(tempB);
    tempData.push(tempC);
  });

  tempData.forEach((item) => {
    if (!chartData[item.utilityName]) {
      chartData[item.utilityName] = {
        name: item.utilityName,
        data: Array(siteLabels.length).fill(0),
      };
    }

    const index = siteLabels.findIndex((e) => e === item.siteName);
    if (index !== -1) {
      if (item.utilityName === "Bill") {
        chartData[item.utilityName].data[index] += parameter1
          ? item[parameter1]
          : item.estimateCo2Emission;
      } else {
        chartData[item.utilityName].data[index] += parameter
          ? item[parameter]
          : item.co2Emission;
      }
    }
  });

  Object.values(chartData).forEach((item: any) => {
    item.data = item.data.map((value: number) =>
      roundToTwoDecimalPlaces(value),
    );
  });

  return Object.values(chartData) || [];
};

function generateMonthsOrder(startMonth: any, endMonth: any) {
  const monthsOrder = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const startIndex = monthsOrder.indexOf(startMonth);
  const endIndex = monthsOrder.indexOf(endMonth);

  if (startIndex === -1 || endIndex === -1) {
    console.error("Invalid startMonth or endMonth.");
    return [];
  }

  if (startIndex <= endIndex) {
    return monthsOrder.slice(startIndex, endIndex + 1);
  }

  return monthsOrder
    .slice(startIndex)
    .concat(monthsOrder.slice(0, endIndex + 1));
}

function getMonthIndex(startMonth: any, endMonth: any, month: any) {
  const monthsOrder = generateMonthsOrder(startMonth, endMonth);
  return monthsOrder.indexOf(month);
}

export const parseEmissionsDataMonthly = (
  data: any,
  startMonth: any,
  endMonth: any,
  parameter = "",
) => {
  const chartData: any = {};

  data.forEach((entry: any) => {
    const utilityName = entry.utilityName;
    if (!(utilityName in chartData)) {
      chartData[utilityName] = new Array(12).fill(0);
    }
  });
  data.forEach((entry: any) => {
    const utilityName = entry.utilityName;
    const monthIndex = getMonthIndex(startMonth, endMonth, entry.month);
    chartData[utilityName][monthIndex] = parameter
      ? entry[parameter]
      : entry.co2Emission;
  });

  const result = Object.keys(chartData).map((utilityName) => ({
    name: utilityName,
    data: chartData[utilityName],
  }));

  return result;
};

export const parseEmissionsScopeDataMonthly = (
  data: any,
  startMonth: any,
  endMonth: any,
) => {
  const chartData: any = {};

  data.forEach((entry: any) => {
    const scope = entry.scope;
    if (!(scope in chartData)) {
      chartData[scope] = new Array(12).fill(0);
    }
  });
  data.forEach((entry: any) => {
    const scope = entry.scope;
    const monthIndex = getMonthIndex(startMonth, endMonth, entry.month);
    chartData[scope][monthIndex] = entry.co2Emission;
  });

  const result = Object.keys(chartData).map((scope) => ({
    name: scope,
    data: chartData[scope],
  }));

  return result;
};

export const parseEmissionsScopDataMonthly = (
  data: any,
  reqYear: string,
  parameter = "",
) => {
  const chartData: any = {};
  Object.keys(data).forEach((key) => {
    if (key === reqYear) {
      const currentYearData = data[key];
      currentYearData.forEach((item: any) => {
        if (
          item.scope === "null" ||
          item.scope === null ||
          item.scope === undefined ||
          item.scope === "undefined" ||
          item.scope === ""
        ) {
          delete chartData[item.scope];
          return;
        }

        if (!chartData[item.scope]) {
          chartData[item.scope] = {
            name: item.scope,
            data: Array(12).fill(0),
          };
        }
        const valueToAdd = parameter ? item[parameter] : item.co2Emission;
        if (valueToAdd !== null) {
          chartData[item.scope].data[MonthMap[item.month]] = valueToAdd;
        }
      });
    }
  });

  return Object.values(chartData) || [];
};

export const parseEstimatesEmissionsDataMonthly = (
  data: any,
  startMonth: any,
  endMonth: any,
  parameter = "",
  parameter1 = "",
) => {
  const chartData: any = {};
  const tempData: any[] = [];

  data.forEach((item: any) => {
    const tempD = JSON.parse(JSON.stringify(item));
    const tempB = tempD;
    tempB.utilityName = "Estimates";
    const tempC = item;
    tempC.utilityName = "Bill";
    tempData.push(tempB);
    tempData.push(tempC);
  });
  tempData.forEach((item: any) => {
    if (!chartData[item?.utilityName]) {
      chartData[item?.utilityName] = {
        name: item?.utilityName,
        data: Array(12).fill(0),
      };
    }
    if (item?.utilityName === "Bill") {
      chartData[item?.utilityName].data[
        getMonthIndex(startMonth, endMonth, item.month)
      ] = parameter1 ? item[parameter1] : item.estimateCo2Emission;
    } else {
      chartData[item?.utilityName].data[
        getMonthIndex(startMonth, endMonth, item.month)
      ] = parameter ? item[parameter] : item.co2Emission;
    }
  });

  Object.values(chartData).forEach((item: any) => {
    item.data = item.data.map((value: number) =>
      roundToTwoDecimalPlaces(value),
    );
  });

  return Object.values(chartData) || [];
};

export const parseUtilitiesByYear = (
  data: any,
  year: string,
  parameter = "",
) => {
  const chartData: any = { category: "CO2", values: [0, 0] };
  Object.keys(data).forEach((key) => {
    if (+key === +year) {
      const currentYearArr = data[key];
      currentYearArr.forEach((item: any) => {
        if (item.utilityName === "ELECTRICITY") {
          chartData.values[1] = parameter ? parseFloat(item[parameter]).toFixed(2) : parseFloat(item.co2Emission).toFixed(2);
        } else {
          chartData.values[0] += parameter ? parseFloat(item[parameter]) : parseFloat(item.co2Emission);
        }
      });
      // Round off the summed value to two decimal digits
      chartData.values[0] = chartData.values[0].toFixed(2);
    }
  });
  return [chartData];
};


export const parseScopesByYear = (data: any, year: string) => {
  const chartData: any = { category: "CO2", values: [] };
  Object.keys(data).forEach((key) => {
    if (+key === +year) {
      const currentYearArr = data[key];
      currentYearArr.forEach(
        (item: { year: number; co2Emission: number; scope: string }) => {
          chartData.values.push(parseFloat(item.co2Emission.toFixed(2)));
        },
      );
    }
  });
  return [chartData];
};


export function monthToIndex(month: string) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months.indexOf(month);
}

export function filterDataWithFiscalYear(
  data: any,
  yearValue: number,
  startMonth: string,
  endMonth: string,
) {
  // Initialize an array to store filtered data
  let filteredData = [];

  // Check if the startMonth is January
  if (startMonth.toLowerCase() === "jan") {
    // Loop through each year in the data
    for (const year in data) {
      // Convert year from string to number for comparison
      const yearNum = parseInt(year);

      // If the year matches yearValue
      if (yearNum === yearValue) {
        // Loop through each entry in the year's data
        for (const entry of data[year]) {
          // Add the entry to the filtered data
          filteredData.push(entry);
        }
      }
    }
  } else {
    // Loop through each year in the data
    for (const year in data) {
      // Convert year from string to number for comparison
      const yearNum = parseInt(year);

      // If the year matches yearValue or yearValue - 1
      if (yearNum === yearValue || yearNum === yearValue - 1) {
        // Loop through each entry in the year's data
        for (const entry of data[year]) {
          // If the entry month falls within the specified range
          if (
            (yearNum === yearValue - 1 &&
              monthToIndex(entry.month) >= monthToIndex(startMonth)) ||
            (yearNum === yearValue &&
              monthToIndex(entry.month) <= monthToIndex(endMonth))
          ) {
            // Add the entry to the filtered data
            filteredData.push(entry);
          }
        }
      }
    }
  }

  return filteredData;
}

const roundToTwoDecimalPlaces = (num: number) => {
  return parseFloat(num.toFixed(2));
};

export const parseYearEmissionsData = (
  response: any,
  parameter = "",
  startMonth: any,
  endMonth: any,
) => {
  const chartData: any = {};
  const modifiedData: any = {};

  const yearsList = Object.keys(response).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const data = filterDataWithFiscalYear(response, year, startMonth, endMonth);
    modifiedData[year] = data;
  });

  yearsList.forEach((key, index) => {
    const currentYeardata = modifiedData[key];
    currentYeardata.forEach((item: any) => {
      if (!chartData[item.utilityName]) {
        chartData[item.utilityName] = {
          name: item.utilityName,
          data: Array(yearsList.length).fill(0),
        };
      }
      chartData[item.utilityName].data[index] += parameter
        ? item[parameter]
        : item.co2Emission;
    });
  });

  Object.values(chartData).forEach((item: any) => {
    item.data = item.data.map((value: number) =>
      roundToTwoDecimalPlaces(value),
    );
  });

  return Object.values(chartData) || [];
};
export const parseYearEmissionsScopeData = (
  response: any,
  parameter = "",
  startMonth: any,
  endMonth: any,
) => {
  const chartData: any = {};
  const modifiedData: any = {};

  const yearsList = Object.keys(response).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const data = filterDataWithFiscalYear(response, year, startMonth, endMonth);
    modifiedData[year] = data;
  });
  yearsList.forEach((key, index) => {
    const currentYeardata = modifiedData[key];
    currentYeardata.forEach((item: any) => {
      if (!chartData[item.scope]) {
        chartData[item.scope] = {
          name: item.scope,
          data: Array(yearsList.length).fill(0),
        };
      }
      chartData[item.scope].data[index] += parameter
        ? item[parameter]
        : item.co2Emission;
    });
  });

  // Round off the final values
  Object.values(chartData).forEach((scopeData: any) => {
    scopeData.data = scopeData.data.map((value: number) =>
      roundToTwoDecimalPlaces(value),
    );
  });


  return Object.values(chartData) || [];
};

export const parseRegionEmissionsScopeData = (
  response: any,
  countryRegionList: any,
  parameter = "",
) => {
  const chartData: any = {};
  const yearsList = Object.keys(response).sort();
  
  yearsList.forEach((key, index) => {
    const currentYearData = response[key];
    
    currentYearData.forEach((item: any) => {
      if (!chartData[item.scope]) {
        chartData[item.scope] = {
          name: item.scope,
          data: Array(yearsList.length).fill(0),
        };
      }
      
      const value = parameter ? item[parameter] : item.co2Emission;
      chartData[item.scope].data[index] = parseFloat(value.toFixed(2));
    });
  });
  
  return Object.values(chartData) || [];
};


export const parseRegionsEmissionsData = (
  data: any,
  regionsList: string[],
  parameter = "",
  startMonth: any,
  endMonth: any,
) => {
  const chartData: any = {};
  const modifiedData: any = {};

  const yearsList = Object.keys(data).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const filterData = filterDataWithFiscalYear(
      data,
      year,
      startMonth,
      endMonth,
    );
    modifiedData[year] = filterData;
  });

  Object.keys(modifiedData).forEach((key) => {
    const currentYearData = modifiedData[key];
    if (!chartData[key]) {
      chartData[key] = {
        name: `FY${key}`,
        data: Array(regionsList.length).fill(0),
      };
    }
    currentYearData.forEach((item: any) => {
      const index = regionsList.findIndex((e) => e === item.countryRegion);
      if (index !== -1) {
        const value = parameter ? item[parameter] : item.co2Emission;
        chartData[key].data[index] = parseFloat(value.toFixed(2));
      }
    });
  });

  return Object.values(chartData) || [];
};


export const parseRegionEmissionsScopeWise = (
  response: any,
  countryRegionList: any,
) => {
  const chartData: any = {};
  const scopes: string[] = ["scope1", "scope2"]; // Define the scopes

  response?.forEach((item: any) => {
    scopes.forEach((scope) => {
      if (!chartData[scope]) {
        chartData[scope] = {
          name: scope,
          data: Array(countryRegionList.length).fill(0),
        };
      }
      const regionIndex = countryRegionList.indexOf(item.countryRegion);
      if (item.scope === scope && regionIndex !== -1) {
        chartData[scope].data[regionIndex] = parseFloat(item.co2Emission.toFixed(2));
      }
    });
  });

  return Object.values(chartData) || [];
};


export const parseFTEmissionsData = (
  data: any,
  facilityTypesList: string[],
  parameter = "",
  startMonth: any,
  endMonth: any,
) => {
  const chartData: any = {};
  const modifiedData: any = {};

  const yearsList = Object.keys(data).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const filterData = filterDataWithFiscalYear(
      data,
      year,
      startMonth,
      endMonth,
    );
    modifiedData[year] = filterData;
  });

  Object.keys(modifiedData).forEach((key) => {
    const currentYearData = modifiedData[key];
    if (!chartData[key]) {
      chartData[key] = {
        name: key,
        data: Array(facilityTypesList.length).fill(0),
      };
    }
    currentYearData.forEach((item: any) => {
      const index = facilityTypesList.findIndex((e) => e === item.facilityType);
      if (index !== -1) {
        const value = parameter ? item[parameter] : item.co2Emission;
        chartData[key].data[index] += parseFloat(value?.toFixed(2));
      }
    });
  });
  return Object.values(chartData) || [];
};

export const parseUtilityEmissionsData = (
  data: any,
  utList: any,
  parameter = "",
  startMonth: any,
  endMonth: any,
) => {
  const utLabels = utList.map((i: any) => i.label);
  const chartData: any = {};
  const modifiedData: any = {};

  const yearsList = Object.keys(data).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const filteredData = filterDataWithFiscalYear(
      data,
      year,
      startMonth,
      endMonth,
    );
    modifiedData[year] = filteredData;
  });

  Object.keys(modifiedData).forEach((key) => {
    const currentYearData = modifiedData[key];
    if (!chartData[key]) {
      chartData[key] = {
        name: `FY${key}`,
        data: Array(utLabels.length).fill(0),
      };
    }
    currentYearData.forEach((item: any) => {
      const index = utLabels.findIndex(
        (e: any) => e && e.toUpperCase() === item.utilityName.toUpperCase(),
      );
      if (index !== -1) {
        const value = parameter ? item[parameter] : item.unitLevelCostData;
        chartData[key].data[index] += parseFloat(value && value?.toFixed(2));
      }
    });
  });
  return Object.values(chartData) || [];
};


export const parseCountriesEmissionsData = (
  data: any,
  labels: string[],
  parameter = "",
  startMonth: any,
  endMonth: any,
) => {
  const chartData: any = {};
  const modifiedData: any = {};

  const yearsList = Object.keys(data).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const filterData = filterDataWithFiscalYear(
      data,
      year,
      startMonth,
      endMonth,
    );
    modifiedData[year] = filterData;
  });

  Object.keys(modifiedData).forEach((key) => {
    const currentYearData = modifiedData[key];
    if (!chartData[key]) {
      chartData[key] = { name: `FY${key}`, data: Array(labels.length).fill(0) };
    }
    currentYearData.forEach((item: any) => {
      const index = labels.findIndex((e) => e === item.country);
      if (index !== -1) {
        const value = parameter ? item[parameter] : item.co2Emission;
        chartData[key].data[index] = parseFloat(value?.toFixed(2));
      }
    });
  });
  return Object.values(chartData) || [];
};


export const parseMonthEmissionsData = (
  data: any,
  parameter = "emissionBySpend",
) => {
  const chartData: any = {
    colorByPoint: true,
    data: [],
  };

  data.forEach((item: any) => {
    const yearSuffix = item.year.toString().slice(-2);
    const monthName = `${item.month}'${yearSuffix}`;
    const index = chartData.data.findIndex((d: any) => d.name === monthName);

    if (index === -1) {
      chartData.data.push({ name: monthName, y: parseFloat(item[parameter].toFixed(2)) });
    } else {
      chartData.data[index].y += parseFloat(item[parameter].toFixed(2));
    }
  });

  // Ensure all values are rounded to two decimal places
  chartData.data.forEach((d: any) => {
    d.y = parseFloat(d.y.toFixed(2));
  });

  return chartData;
};


export const parseSiteSpecificData = (
  data: any,
  year: string,
  parameter: string,
  forEmissions = true,
) => {
  const chartData: any = {};
  let totalParametersList: Array<any> = [];
  Object.keys(data).forEach((key) => {
    if (key === year) {
      const currentYearData = data[key];
      totalParametersList = currentYearData.map((e: any) => e[parameter]);
      currentYearData.forEach((item: any) => {
        if (!chartData[item.utilityName]) {
          chartData[item.utilityName] = {
            name: item.utilityName,
            data: Array(totalParametersList.length).fill(0),
          };
        }
        const index = totalParametersList.findIndex(
          (e: string) => e === item[parameter],
        );
        if (parameter === "amount") {
          chartData[item.utilityName].data[index] = parseFloat(item[parameter].toFixed(2));
        } else if (index !== -1) {
          chartData[item.utilityName].data[index] = parseFloat(
            (forEmissions ? item.co2Emission : item.consumption).toFixed(2)
          );
        }
      });
    }
  });
  return {
    series: Object.values(chartData) || [],
    categories: totalParametersList,
  };
};


export const parseSiteSpecificDataBasedOnAcct = (
  data: any,
  parameter: string,
  reqYear: string,
) => {
  const chartData: any = {};
  const totalAcctNumList: Array<any> = [];
  Object.keys(data).forEach((key) => {
    if (key === reqYear) {
      const currentYearData = data[key];
      Object.keys(currentYearData).forEach((acc) => {
        totalAcctNumList.push(acc);
      });
    }
  });
  Object.keys(data).forEach((key) => {
    if (key === reqYear) {
      const currentYearData = data[key];
      Object.keys(currentYearData).forEach((acc) => {
        const currentAccData = currentYearData[acc];
        currentAccData.forEach((item: any) => {
          if (item.meterNumber && !chartData[item.meterNumber]) {
            chartData[item.meterNumber] = {
              name: item.meterNumber,
              data: Array(totalAcctNumList.length).fill(0),
            };
          }
          const index = totalAcctNumList.findIndex(
            (e: string) => e === item.accountNumber,
          );
          if (item.meterNumber && index !== -1) {
            chartData[item.meterNumber].data[index] = item[parameter];
          }
        });
      });
    }
  });
  return {
    series: Object.values(chartData) || [],
    categories: totalAcctNumList,
  };
};

export const parseSiteSpecificConsumptionData = (
  data: any,
  reqYear: string,
  utilityName = UTILITY_NAMES.ELECTRICITY,
) => {
  const chartData: any = { colorByPoint: true, data: [] };
  Object.keys(data).forEach((key) => {
    if (key === reqYear) {
      const currentYearData = data[key];
      currentYearData.forEach((item: any) => {
        if (utilityName === item.utilityName) {
          const obj: any = {
            name: item.meterNumber
              ? `${item.accountNumber}_${item.meterNumber}`
              : `${item.accountNumber}`,
            y: parseFloat(item.consumption.toFixed(2)), // Round to two decimal places
          };
          chartData.data.push(obj);
        }
      });
    }
  });
  return chartData;
};

export const parseConsumptionDataMonthly = (
  data: any,
  startMonth: string,
  endMonth: string,
  utilityTypeName: any,
) => {
  const chartData: (number | null)[] = new Array(12).fill(0);
  const monthsOrder: string[] = generateMonthsOrder(startMonth, endMonth);

  // Filter data for the specified utilityTypeName
  const filteredData = data.filter(
    (entry: any) => entry.utilityName === utilityTypeName,
  );

  // Assign CO2 emissions to the appropriate months
  filteredData.forEach((entry: any) => {
    const monthIndex = getMonthIndex(startMonth, endMonth, entry.month);
    chartData[monthIndex] = entry.consumption;
  });

  // Format the result
  const result = [
    {
      name: utilityTypeName,
      data: chartData.map((value, index) => (value !== 0 ? value : null)),
    },
  ];
  return result;
};

export const parseSiteConsumptionData = (
  data: any,
  siteOptions: MultiSelectControlledOption[],
  utilityName = UTILITY_NAMES.ELECTRICITY,
  startMonth: any,
  endMonth: any,
) => {
  const siteLabels = siteOptions.map((i) => i.label);
  const chartData: any = {};
  const modifiedData: any = {};

  const yearsList = Object.keys(data).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const filterData = filterDataWithFiscalYear(
      data,
      year,
      startMonth,
      endMonth,
    );
    modifiedData[year] = filterData;
  });

  Object.keys(modifiedData).forEach((key) => {
    const currentYearData = modifiedData[key];
    currentYearData.forEach((item: any) => {
      if (item.utilityName === utilityName && item.consumption !== null && item.consumption !== undefined) {
        if (!chartData[key]) {
          chartData[key] = {
            name: key,
            data: customTooltipDataGen(siteLabels.length, item.unit),
          };
        }
        const index = siteLabels.findIndex((e) => e === item.siteName);
        if (index !== -1) {
          // Round the consumption value to two decimal places
          const roundedConsumption = parseFloat(item.consumption.toFixed(2));
          chartData[key].data[index].y += roundedConsumption;
        }
      }
    });
  });
  return Object.values(chartData) || [];
};


export const parseYearConsumptionData = (
  response: any,
  parameter: string,
  utilityName: any,
  startMonth: any,
  endMonth: any,
) => {
  const chartData: any = {};
  const modifiedData: any = {};

  const yearsList = Object.keys(response).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const data = filterDataWithFiscalYear(response, year, startMonth, endMonth);
    modifiedData[year] = data;
  });

  yearsList.forEach((key, index) => {
    const currentYeardata = modifiedData[key];
    currentYeardata.forEach((item: any) => {
      if (item.utilityName === utilityName) {
        if (!chartData[item.utilityName]) {
          chartData[item.utilityName] = {
            name: item.utilityName,
            data: Array(yearsList.length).fill(0),
          };
        }
        chartData[item.utilityName].data[index] += parameter
          ? item[parameter]
          : item.consumption;
      }
    });
  });
  Object.values(chartData).forEach((item: any) => {
    item.data = item.data.map((value: number) =>
      roundToTwoDecimalPlaces(value),
    );
  });
  return Object.values(chartData) || [];
};

export const parseRegionsConsumptionData = (
  data: any,
  regionsList: string[],
  utilityName = UTILITY_NAMES.ELECTRICITY,
  startMonth: string,
  endMonth: string,
) => {
  const chartData: any = {};
  const modifiedData: any = {};

  const yearsList = Object.keys(data).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const filterData = filterDataWithFiscalYear(
      data,
      year,
      startMonth,
      endMonth,
    );
    modifiedData[year] = filterData;
  });

  Object.keys(modifiedData).forEach((key) => {
    const currentYearData = modifiedData[key];
    currentYearData.forEach((item: any) => {
      if (item.utilityName === utilityName && item.consumption !== null && item.consumption !== undefined) {
        if (!chartData[key]) {
          chartData[key] = {
            name: `FY${key}`,
            data: customTooltipDataGen(regionsList.length, item.unit),
          };
        }
        const index = regionsList.findIndex((e) => e === item.countryRegion);
        if (index !== -1) {
          // Round the consumption value to two decimal places
          const roundedConsumption = parseFloat(item.consumption.toFixed(2));
          chartData[key].data[index].y += roundedConsumption;
        }
      }
    });
  });

  return Object.values(chartData) || [];
};


export const parseFTConsumptionData = (
  data: any,
  facilityTypesList: string[],
  utilityName: any,
  startMonth: string,
  endMonth: string,
) => {
  const chartData: any = {};
  const modifiedData: any = {};

  const yearsList = Object.keys(data).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const filterData = filterDataWithFiscalYear(
      data,
      year,
      startMonth,
      endMonth,
    );
    modifiedData[year] = filterData;
  });

  Object.keys(modifiedData).forEach((key) => {
    const currentYearData = modifiedData[key];
    currentYearData.forEach((item: any) => {
      if (item.utilityName === utilityName && item.consumption !== null && item.consumption !== undefined) {
        if (!chartData[key]) {
          chartData[key] = {
            name: `FY${key}`,
            data: customTooltipDataGen(facilityTypesList.length, item.unit),
          };
        }
        const index = facilityTypesList.findIndex(
          (e) => e === item.facilityType,
        );
        if (index !== -1) {
          // Round the consumption value to two decimal places
          const roundedConsumption = parseFloat(item.consumption.toFixed(2));
          chartData[key].data[index].y += roundedConsumption;
        }
      }
    });
  });

  return Object.values(chartData) || [];
};


export const parseCountriesConsumptionData = (
  data: any,
  labels: string[],
  utilityName = UTILITY_NAMES.ELECTRICITY,
  startMonth: any,
  endMonth: any,
) => {
  const chartData: any = {};
  const modifiedData: any = {};

  const yearsList = Object.keys(data).sort();
  yearsList.forEach((key) => {
    const year = parseInt(key, 10);
    const filterData = filterDataWithFiscalYear(
      data,
      year,
      startMonth,
      endMonth,
    );
    modifiedData[year] = filterData;
  });

  Object.keys(modifiedData).forEach((key) => {
    const currentYearData = modifiedData[key];
    currentYearData.forEach((item: any) => {
      if (item.utilityName === utilityName && item.consumption !== null && item.consumption !== undefined) {
        if (!chartData[key]) {
          chartData[key] = {
            name: `FY${key}`,
            data: customTooltipDataGen(labels.length, item.unit),
          };
        }
        const index = labels.findIndex((e) => e === item.country);
        if (index !== -1) {
          // Round the consumption value to two decimal places
          const roundedConsumption = parseFloat(item.consumption.toFixed(2));
          chartData[key].data[index].y += roundedConsumption;
        }
      }
    });
  });

  return Object.values(chartData) || [];
};

export const findCommonElements = (...arrays: any[][]) => {
  const flattenedArray = arrays.flat().flat();
  const uniqueElements = new Set(flattenedArray);
  const commonElements = Array.from(uniqueElements).filter((element) =>
    arrays.every((arr) => arr.includes(element)),
  );
  return commonElements;
};

export const generateMonths = (
  startMonthInput: string,
  endMonthInput: string,
  year: number,
) => {
  // List of all months in short form
  const monthsShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Convert input months to title case for comparison
  const startMonth =
    startMonthInput.charAt(0).toUpperCase() +
    startMonthInput.slice(1).toLowerCase();
  const endMonth =
    endMonthInput.charAt(0).toUpperCase() +
    endMonthInput.slice(1).toLowerCase();

  // Find the index of the start and end months
  const startIndex = monthsShort.findIndex(
    (month) => month.toLowerCase() === startMonth.toLowerCase(),
  );
  const endIndex = monthsShort.findIndex(
    (month) => month.toLowerCase() === endMonth.toLowerCase(),
  );

  // If both start and end months are found
  if (startIndex !== -1 && endIndex !== -1) {
    // Determine the year for the start month
    const startYear = endIndex < startIndex ? year - 1 : year;

    // If end month comes before start month or they are the same months
    if (endIndex <= startIndex) {
      // Generate months from start month to December
      const monthsFromStart = monthsShort
        .slice(startIndex)
        .map((month) => `${startYear} ${month}`);
      // Generate months from January to end month
      const monthsFromEnd = monthsShort
        .slice(0, endIndex + 1)
        .map((month) => `${year} ${month}`);
      // Concatenate the two arrays
      return monthsFromStart.concat(monthsFromEnd);
    }
    // Generate months from start month to end month
    return monthsShort
      .slice(startIndex, endIndex + 1)
      .map((month) => `${year} ${month}`);
  }
  return []; // Return empty array if start or end month is not found
};
