import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FC, useEffect, useMemo, useState } from "react";

import { createSite, getRegion, updateSite } from "api";

import { useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { COLORS, InputFieldWrapper, TYPOGRAPHY_COMMON_STYLES } from "components";
import { Controller, useForm } from "react-hook-form";
import { AllowedFormValues } from "../types";

interface AddSiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  allowedValues: AllowedFormValues;
  editSite: any;
}

export const AddSiteModal: FC<AddSiteModalProps> = ({
  isOpen,
  onClose,
  allowedValues,
  editSite,
}) => {
  const { countries, siteStatuses, siteTypes, regions, countryRegions } =
    allowedValues;
  const sortedRegions = regions.sort((a, b) => a.localeCompare(b));
  const sortedRegionsAccending = sortedRegions?.sort() || [];
  const sortedCountries = countryRegions.sort((a, b) => a.localeCompare(b));
  const CountriesAccending = countries?.sort() || [];
  const siteTypesAccending = siteTypes?.sort() || [];


  const notify = useNotify();
  const queryClient = useQueryClient();

  const defaultValues: any = useMemo(() => (
    {
      name: editSite?.name || null,
      address: editSite?.address  || null,
      city: editSite?.city  || null,
      zipCode: editSite?.zipCode  || null,
      type: editSite?.type  || null,
      status: editSite?.status  || null,
      country: editSite?.country  || null,
      countryRegion: editSite?.countryRegion  || null,
      region: editSite?.region  || null,
      customerSiteId: editSite?.customerSiteId || null 
    }
  ), [editSite]);

  const { control, reset, getValues, watch, setValue} = useForm<any>({
    mode: "onChange",
    defaultValues,
  });

  // Value of Country and zipCode
  const [watchedCountryValue, watchedZipValue] = watch(['country', 'zipCode']);

  const [formValues, setFormValues] = useState<any>({});

  useEffect(() => {
    reset(defaultValues);
    setFormValues(editSite)
  }, [reset, defaultValues, editSite]);

  const { mutateAsync: createSiteRequest } = useMutation(createSite);
  const { mutateAsync: updateSiteRequest } = useMutation(updateSite);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!editSite && watchedCountryValue !== undefined) {
        getRegionCode(watchedZipValue, watchedCountryValue);
      }
    }, 1500);

    return () => {
      clearTimeout(handler);
    };

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedCountryValue, watchedZipValue, editSite])


  useEffect(() => {
    if (editSite && formValues?.multipleRegion === true && formValues?.regionUpdated === false) {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        ["region" as string]: "MULTIPLE REGIONS FOUND",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.multipleRegion, formValues?.regionUpdated])


  async function fetchData(dataset: any) {
    const dataResponse = await getRegion(dataset);
    const regionValue = dataResponse[0];
    if (regionValue !== undefined && regionValue != null) {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        ["region" as string]: regionValue,
      }));
    } else {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        ["region" as string]: null,
      }));
    }
  }

  const getRegionCode = (zipCode: any, country: any) => {
    if (country !== null) {
      const dataset = { zipCode: zipCode !== undefined ? zipCode : null, country }
      fetchData(dataset);
    }
  };

  const handleAddSite = async () => {
    await createSiteRequest(getValues(), {
      onError: () => notify.error(`Failed to create site: ${getValues().name}!`),
      onSuccess: () => {
        notify.success(`Site successfully created: ${getValues().name}!`);
        queryClient.invalidateQueries([QUERY_KEYS.SITES]);
      },
    });
    reset(defaultValues);
    onClose();
  };

  const handleInputChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const { name, value } = e.target;
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name as string]: value,
    }));
  };

  const handleEditSite = async () => {
    const { id } = editSite;
    if (formValues.region === "MULTIPLE REGIONS FOUND") {
      notify.warning("Please make sure to select an Electricity Region Code to calculate your emissions.");
    } else {

      const updatedSite  = {
        ...formValues,
        id,
        name: getValues().name === "" ? null : getValues().name,
        address: getValues().address === "" ? null : getValues().address,
        city: getValues().city === "" ? null : getValues().city,
        zipCode: getValues().zipCode === "" ? null : getValues().zipCode,
        country: getValues().country === "" ? null : getValues().country,
        type: getValues().type === "" ? null : getValues().type,
        status: getValues().status === "" ? null : getValues().status,
        countryRegion: getValues().countryRegion === "" ? null : getValues().countryRegion,
        region: getValues().region === "" ? null : getValues().region,
        customerSiteId: getValues()?.customerSiteId === "" ? null : getValues()?.customerSiteId
    };

      await updateSiteRequest(updatedSite, {
        onError: () => notify.error(`Failed to edit site: ${formValues.name}!`),
        onSuccess: () => {
          notify.success(`Site successfully edited: ${formValues.name}!`);
          queryClient.invalidateQueries([QUERY_KEYS.SITES]);
        },
      });
      setFormValues({});
      onClose();
    }
  };

  const handleCancel = () => {
    if (editSite) {
      onClose();
    } else {
      onClose();
      setFormValues({});
      reset(defaultValues);
    }
  }

  useEffect(() => {
    setValue("region", formValues?.region)
  },[formValues?.region, setValue])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{editSite ? "Edit Site" : "Add New Site"}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: "100%",
              minWidth: { xs: "300px", sm: "360px", md: "400px" },
              gap: "1.5rem",
              marginTop: "7px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputFieldWrapper label="Site Name">
                  <Controller
                    name="name"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        helperText={error ? error.message : null}
                        size="small"
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </InputFieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputFieldWrapper label="Customer Site ID">
                  <Controller
                    name="customerSiteId"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        helperText={error ? error.message : null}
                        size="small"
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </InputFieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputFieldWrapper label="Street Address">
                  <Controller
                    name="address"
                    control={control}
                    render={({
                      fieldState: { error },
                      field: { onChange, value },
                    }) => (
                      <TextField
                        helperText={error ? error.message : null}
                        size="small"
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </InputFieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputFieldWrapper label="City">
                  <Controller
                    name="city"
                    control={control}
                    render={({
                      fieldState: { error },
                      field: { onChange, value },
                    }) => (
                      <TextField
                        helperText={error ? error.message : null}
                        size="small"
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </InputFieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputFieldWrapper label="Zipcode">
                  <Controller
                    name="zipCode"
                    control={control}
                    render={({
                      fieldState: { error },
                      field: { onChange, value },
                    }) => (
                      <TextField
                        helperText={error ? error.message : null}
                        size="small"
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </InputFieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputFieldWrapper label="Type">
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select
                          labelId="type-simple-select-label"
                          id="type-simple-select"
                          autoWidth
                          fullWidth
                          sx={{ height: 40 }}
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                width: 260, 
                              },
                            },
                          }}
                        >
                          {siteTypesAccending.map((t) => (
                            <MenuItem
                            sx={{
                              fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                              fontSize: 14,
                              lineHeight: "18px",
                              color: '#90959E',
                              '&:hover': {
                                backgroundColor: '#F6F8FA',
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#F6F8FA',
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                            }} key={t} value={t}>
                              {t}</MenuItem>
                          ))}
                        </Select>
                    )
                    }
                  />
                </InputFieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputFieldWrapper label="Status">
                  <Controller
                    name="status"
                    control={control}
                    render={({field}) =>  (
                        <Select
                          labelId="status-simple-select-label"
                          id="status-simple-select"
                          autoWidth
                          fullWidth
                          sx={{ height: 40 }}
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                width: 260,
                              },
                            },
                          }}
                        >
                          {siteStatuses.map((t) => (
                            <MenuItem
                            sx={{
                              fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                              fontSize: 14,
                              lineHeight: "18px",
                              color: '#90959E',
                              '&:hover': {
                                backgroundColor: '#F6F8FA',
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#F6F8FA',
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                            }} key={t} value={t}>
                              {t}</MenuItem>
                          ))}
                        </Select>
                      )
                    }
                  />
                </InputFieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputFieldWrapper label="Country">
                  <Controller
                    name="country"
                    control={control}
                    render={({field}) => (
                        <Select
                          labelId="country-simple-select-label"
                          id="country-simple-select"
                          autoWidth
                          fullWidth
                          sx={{ height: 40 }}
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                width: 260,
                              },
                            },
                          }}
                        >
                          {CountriesAccending.map((t) => (
                            <MenuItem
                            sx={{
                              fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                              fontSize: 14,
                              lineHeight: "18px",
                              color: '#90959E',
                              '&:hover': {
                                backgroundColor: '#F6F8FA',
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#F6F8FA',
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                            }} key={t} value={t}>
                              {t}</MenuItem>
                          ))}
                        </Select>
                      )
                    }
                  />
                </InputFieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputFieldWrapper label="Country Code">
                  <Controller
                    name="countryRegion"
                    control={control}
                    render={({field}) => (
                      <Select
                          labelId="countryRegion-simple-select-label"
                          id="countryRegion-simple-select"
                          autoWidth
                          fullWidth
                          sx={{ height: 40 }}
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                width: 260,
                              },
                            },
                          }}
                        >
                          {sortedCountries.map((t) => (
                            <MenuItem
                            sx={{
                              fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                              fontSize: 14,
                              lineHeight: "18px",
                              color: '#90959E',
                              '&:hover': {
                                backgroundColor: '#F6F8FA',
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#F6F8FA',
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                            }} key={t} value={t}>
                              {t}</MenuItem>
                          ))}
                        </Select>
                    )
                    }
                  />
                </InputFieldWrapper>
              </Grid>
              <Grid item xs={6}>
                <InputFieldWrapper label="Electricity Region Code">
                  <Controller
                    name="region"
                    control={control}
                    render={() => (
                      <Select
                          labelId="region-simple-select-label"
                          id="region-simple-select"
                          name="region"
                          autoWidth
                          fullWidth
                          sx={{ height: 40, color: (formValues?.region === "MULTIPLE REGIONS FOUND") ? 'red' : 'inherit', }}
                          value={formValues?.region || ""}
                          onChange={(e) => handleInputChange(e as any)}
                          disabled={!editSite}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                width: 260,
                              },
                            },
                          }}
                        >
                          {sortedRegionsAccending.map((t) => (
                            <MenuItem
                            sx={{
                              backgroundColor: formValues?.regionList?.includes(t) ? COLORS.neutralGhWhite : 'inherit', 
                              fontWeight: TYPOGRAPHY_COMMON_STYLES.fontWeightSemiBold,
                              fontSize: 14,
                              lineHeight: "18px",
                              color: '#90959E',
                              '&:hover': {
                                backgroundColor: '#F6F8FA',
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                              '&.Mui-selected': {
                                backgroundColor: '#F6F8FA',
                                borderRadius: 5,
                                color: COLORS.Romance,
                              },
                            }} key={t} value={t}>
                              {t}</MenuItem>
                          ))}
                          <MenuItem
                            value="MULTIPLE REGIONS FOUND"
                            sx={{
                              display: 'none'
                            }}
                          >
                            Multiple Region Codes Found
                          </MenuItem>
                        </Select>
                    )
                    }
                  />
                </InputFieldWrapper>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          onClick={editSite ? handleEditSite : handleAddSite}
          variant="contained"
          color="primary"
        >
          {editSite ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
