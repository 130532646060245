/* eslint-disable */
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Stack,
  styled,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  EmissionsOnMonthBody,

  getCountriesEmissionsData,
  getEmissionsByFacilityType,
  getEmissionsByRegion,
  getEmissionsByScope,
  getEmissionsBySite,
  getEmissionsByUtilityType,
  getEmissionsOnMonth,
  getEmissionsScopeYearly,
  getEmissionsYearly,
  getEstimatesBySite,
  getEstimatesOnMonth,
  getSiteSpecificEmissionsData,

  getConsumptionsByFacilityType,
  getConsumptionsByRegion,
  getConsumptionsBySite,
  getConsumptionsOnMonth,
  getConsumptionsYearly,
  getCountriesConsumptionData,
  getSiteSpecificConsumptionData,

  getCountriesFinacialsData,
  getFinancialEmissionSpendData,
  getFinancialsByFacilityType,
  getFinancialsByRegion,
  getFinancialsBySite,
  getFinancialsByUtilityType,
  getFinancialsOnMonth,
  getFinancialsYearly,

  getScopeOnMonth,
  getSpendAccData,
  getUnitLevelCostData,
} from "api/dashboards";

import {
  AutocompleteField,
  COLORS,
  FILTERS_AND_ACTIONS_WRAPPER,
  FLEX_ALIGN_CENTER_GAP_2, Loading, // MarginWrapper,
  MultiSelectControlledOption,
  MultiselectControlledField, // Tabs,
  usePagination,
} from "components";
import { SelectControlledField } from "components/inputs/select-field";

import {
  useAccountNumbersMultiselect,
  useCountriesSelect,
  useCountryRegionsSelect,
  useFacilityTypesSelect,
  useGetConfigParameter,
  useMeterNumbersMultiselect,
  useUtilityBillsYears,
  useUtilityTypesMultiselect,
} from "hooks";
import { useFilteredSitesMultiselect } from "hooks/multiselect/filteredSites";
import { useScopeSelect } from "hooks/singleselect/scope";

import { LOCAL_STORAGE_KEYS, QUERY_PARAMS_KEYS } from "consts";

import { ConsumptionElectricityComponent } from "./components/consumptionElectricity";
import { ConsumptionNaturalGasComponent } from "./components/consumptionNaturalGas";
import { ConsumptionSewerComponent } from "./components/consumptionSewer";
import { ConsumptionWaterComponent } from "./components/consumptionWater";
import { DashboardTabs } from "./components/dashboardTabs";
import { OverallEmissionsComponent } from "./components/overallEmissions";
import { OverallFinancialsComponent } from "./components/overallFinancials";
import { SiteSpecificConsumption } from "./components/siteSpecificConsumption";
import { SiteSpecificEmissions } from "./components/siteSpecificEmissions";
import { DASHBOARD_PAGE } from "./consts";
import { useChartsDataStateHook } from "./hooks/useChartsDataStateHook";
import { useCustomYearsDropdownHook } from "./hooks/useCustomYearsDropdownHook";
import { filterDataWithFiscalYear } from "./utils";
import { debounce } from "lodash";

const MULTISELECT_WRAPPER = { minWidth: "220px", width: "fit-content" };
const PageDiv = styled("div")(() => ({
  maxWidth: "calc(100% - 50px)",
}));
const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const btnStates = {
  GHC_EMISSIONS: "ghc-emission",
  CONSUMPTION: "consumption",
  FINANCIAL: "financial",
  SITE_SPECIFIC: "site-specific",
};

export const DashboardPage = () => {
  const { setInitialPage, updateQueryParamsWithInitialPage } = usePagination({
    pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.DOCUMENT_SUMMARY_PAGE_SIZE,
  });

  const { GHC_EMISSIONS, CONSUMPTION, FINANCIAL, SITE_SPECIFIC } = btnStates;

  const { data: availableYears } = useUtilityBillsYears();

  const { data } = useGetConfigParameter();

  const startMonth =
    data && data.find((obj) => obj.name === "company_month_start")?.configValue;
  const endMonth =
    data && data.find((obj) => obj.name === "company_month_end")?.configValue;

  const currentYear = new Date().getFullYear() - 1;
  const currentFiscalYear = "FY" + currentYear;

  const nextYear = new Date().getFullYear() + 1;

  const fiscalYears: any = availableYears && [
    ...availableYears.map((yearItem: any) => `FY${yearItem}`),
    `FY${nextYear}`,
  ];

  const [fiscalYear, setFiscalYear] = useState(currentFiscalYear);
  const [yearValue, setYearValue] = useState<any>(null);
  const hasFetchedInitially = useRef(false);

  useEffect(() => {
    const yearNumber = parseInt(fiscalYear.replace("FY", ""), 10) as number;
    setYearValue(yearNumber);
  }, []);

  useEffect(() => {
    const yearNumber = parseInt(fiscalYear.replace("FY", ""), 10) as number;
    setYearValue(yearNumber);
  }, [fiscalYear]);

  const [searchParams] = useSearchParams();
  // setSearchParams

  const {
    isCountryRegionsLoading,
    selectedCountryRegion,
    setSelectedCountryRegion,
    countryRegionsList,
  } = useCountryRegionsSelect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const {
    isCountriesLoading,
    selectedCountry,
    setSelectedCountry,
    countriesList,
  } = useCountriesSelect({
    selectedCountryRegion,
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const {
    isFacilityTypesLoading,
    setSelectedFalityTypeOption,
    selectedFacilityType,
    facilityTypesList,
  } = useFacilityTypesSelect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: false,
  });

  const { isScopesLoading, setSelectedScope, selectedScope, scopesList } =
    useScopeSelect({
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
      withEmptyOption: false,
    });

  const { isSitesLoading, selectedSites, setSelectedSites, sitesOptions } =
    useFilteredSitesMultiselect({
      selectedCountry,
      updateExternalStates: setInitialPage,
      getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    });

  const {
    isAccountNumbersLoading,
    selectedAccountNumbers,
    setSelectedAccountNumbers,
    accountNumbersOptions,
  } = useAccountNumbersMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  const {
    isMeterNumbersLoading,
    selectedMeterNumbers,
    setSelectedMeterNumbers,
    meterNumbersOptions,
  } = useMeterNumbersMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  const {
    isUtilityTypesLoading,
    selectedUtilityTypes,
    setSelectedUtilityTypes,
    utilityTypesOptions,
  } = useUtilityTypesMultiselect({
    updateExternalStates: setInitialPage,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialPage,
    withEmptyOption: true,
  });

  useEffect(() => {
    setSelectedCountry("");
  }, [countryRegionsList, selectedCountryRegion]);

  useEffect(() => {
    setSelectedSites([]);
  }, [countriesList, selectedCountry]);

  const filterScopeList = scopesList.filter((item) => item.includes("scope"));

  const filterTypes = utilityTypesOptions.filter((item) => item.label !== "-");
  const utitlityTypesForEmissions = utilityTypesOptions.filter(
    (item: any) => item?.emissions && item.label !== "-",
  );
  const utitlityTypesForConsumption = utilityTypesOptions.filter(
    (item: any) => item?.consumptions && item.label !== "-",
  );
  const utitlityTypesForFinancial = utilityTypesOptions.filter(
    (item: any) => item?.financials && item.label !== "-",
  );

  const filterAccountNumbers = accountNumbersOptions.filter(
    (item) => item.label !== "-",
  );
  const filterMeterNumbers = meterNumbersOptions.filter(
    (item) => item.label !== "-",
  );

  const {
    selectedYear: monthEmissionsYear,
    setSelectedYear: setMonthEmissionsYear,
    yearsList: monthEmYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: regionEmissionsYear,
    setSelectedYear: setRegionEmissionsYear,
    yearsList: regionEmYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthFinancialYear,
    setSelectedYear: setMonthFinancialYear,
    yearsList: monthFnYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: financialCarbonYear,
    setSelectedYear: setFinancialCarbonYear,
    yearsList: financialCarbonYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthConsumptionYear,
    setSelectedYear: setMonthConsumptionYear,
    yearsList: monthCSYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthCSWaterYear,
    setSelectedYear: setMonthCSWaterYear,
    yearsList: monthCSWaterYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthCSSewerYear,
    setSelectedYear: setMonthCSSewerYear,
    yearsList: monthCSSewerYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: monthCSElectricityYear,
    setSelectedYear: setMonthCSElectricityYear,
    yearsList: monthCSElectricityYears,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: scopeYear,
    setSelectedYear: setScopeYear,
    // yearsList: scopeYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: utYear,
    setSelectedYear: setUTYear,
    // yearsList: utYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteEmYear,
    setSelectedYear: setSiteEMyear,
    yearsList: siteEMYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteCostYear,
    setSelectedYear: setSiteCostYear,
    yearsList: siteCostYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteElecYear,
    setSelectedYear: setSiteElecYear,
    yearsList: siteElecYearsList,
  } = useCustomYearsDropdownHook({});

  const {
    selectedYear: siteNGYear,
    setSelectedYear: setSiteNGYear,
    yearsList: siteNGYearsList,
  } = useCustomYearsDropdownHook({});

  const [selectedBtnOption, setSelectedBtnOption] = useState(GHC_EMISSIONS);

  const {
    monthlyScopeData,
    setMonthlyScopeData,
    yearlyScopeData,
    setYearlyScopeData,
    monthlyData,
    setMonthlyData,
    sitesData,
    setSitesData,
    yearlyData,
    setYearlyData,
    regionsData,
    setRegionsData,
    utilityData,
    setUtilityData,
    facilityTypesData,
    setFacilityTypesData,
    scopesResp,
    setScopesResp,
    countriesResp,
    setCountriesResp,
    siteSpecificAccData,
    setSiteSpecificAccData,
    siteSpecificConsumptionData,
    setSiteSpecificConsumptionData,
    setSiteSpendAccData,
    siteSpendAccData,
    monthlyFinancialData,
    setMonthlyFinancialData,
    sitesFinancialData,
    setSitesFinancialData,
    yearlyFinancialData,
    setYearlyFinancialData,
    regionsFinancialData,
    setRegionsFinancialData,
    utilityFinancialData,
    setUtilityFinancialData,
    facilityTypesFinancialData,
    setFacilityTypesFinancialData,
    countriesFinancialResp,
    setCountriesFinancialResp,
    unitLevelCost,
    setUnitLevelCost,
    financialEMSpend,
    setFinancialEMSpend,
    consumptionMonthlyData,
    setConsumptionMonthlyData,
    consumptionSitesData,
    setConsumptionSitesData,
    consumptionYearlyData,
    setConsumptionYearlyData,
    consumptionCountriesResp,
    setConsumptionCountriesResp,
    consumptionFacilityTypesData,
    setConsumptionFacilityTypesData,
    consumptionRegionsData,
    setConsumptionRegionsData,
    estimatesMonthlyData,
    setEstimatesMonthlyData,
    estimatesBySiteData,
    setEstimatesBySiteData,
  } = useChartsDataStateHook();

  // Emission Mutation
  const { mutate: getEmissionsOnMonthly, isLoading: getEmissionsOnMonthlyLoading } = useMutation(getEmissionsOnMonth);
  const { mutate: getEstimatesOnMonthly, isLoading: getEstimatesOnMonthlyLoading } = useMutation(getEstimatesOnMonth);
  const { mutate: getEstimatesSite, isLoading: getEstimatesSiteLoading } = useMutation(getEstimatesBySite);
  const { mutate: getEmissionsSite, isLoading: getEmissionsSiteLoading } = useMutation(getEmissionsBySite);
  const { mutate: getEmissionsUtility, isLoading: getEmissionsUtilityLoading } = useMutation(getEmissionsByUtilityType);
  const { mutate: getEmissionsScope, isLoading: getEmissionsScopeLoading } = useMutation(getEmissionsByScope);
  const { mutate: getEmissionsYear, isLoading: getEmissionsYearLoading } = useMutation(getEmissionsYearly);
  const { mutate: getEmissionsScopeYear, isLoading: getEmissionsScopeYearLoading } = useMutation(getEmissionsScopeYearly);
  const { mutate: getEmissionsRegion, isLoading: getEmissionsRegionLoading } = useMutation(getEmissionsByRegion);
  const { mutate: getEmissionsFacilityType, isLoading: getEmissionsFacilityTypeLoading } = useMutation(getEmissionsByFacilityType);
  const { mutate: getEmissionsByCountries, isLoading: getEmissionsByCountriesLoading } = useMutation(getCountriesEmissionsData);
  const { mutate: getSSEmissionsData, isLoading: getSSEmissionsDataLoading } = useMutation(getSiteSpecificEmissionsData);

  const emissionLoadingStates = {
    getEmissionsOnMonthlyLoading,
    getEmissionsScopeLoading,
    getEmissionsRegionLoading,
    getEmissionsYearLoading,
    getEmissionsSiteLoading,
    getEmissionsScopeYearLoading,
    getEmissionsFacilityTypeLoading,
    getEmissionsUtilityLoading,
    getEstimatesOnMonthlyLoading,
    getEstimatesSiteLoading,
  };

  // Financial Mutation
  const { mutate: getFinancialsOnMonthly, isLoading: getFinancialsOnMonthlyLoading } = useMutation(getFinancialsOnMonth);
  const { mutate: getFinancialsSite, isLoading: getFinancialsSiteLoading } = useMutation(getFinancialsBySite);
  const { mutate: getFinancialsUtility, isLoading: getFinancialsUtilityLoading } = useMutation(getFinancialsByUtilityType);
  const { mutate: getFinancialsYear, isLoading: getFinancialsYearLoading } = useMutation(getFinancialsYearly);
  const { mutate: getFinancialsRegion, isLoading: getFinancialsRegionLoading } = useMutation(getFinancialsByRegion);
  const { mutate: getFinancialsFacilityType, isLoading: getFinancialsFacilityTypeLoading } = useMutation(getFinancialsByFacilityType);
  const { mutate: getFinancialsByCountries, isLoading: getFinancialsByCountriesLoading } = useMutation(getCountriesFinacialsData);
  const { mutate: getFinancialEMSpend, isLoading: getFinancialEMSpendLoading } = useMutation(getFinancialEmissionSpendData);
  const { mutate: getUnitLevelCost, isLoading: getUnitLevelCostLoading } = useMutation(getUnitLevelCostData);

  const financialLoadingStates = {
    getFinancialsOnMonthlyLoading,
    getFinancialsSiteLoading,
    getFinancialsUtilityLoading,
    getFinancialsYearLoading,
    getFinancialsRegionLoading,
    getFinancialsFacilityTypeLoading,
    getFinancialsByCountriesLoading,
    getFinancialEMSpendLoading,
    getUnitLevelCostLoading
  };

  // Consumption Mutation 
  const { mutate: getSSConsumptionData, isLoading: getSSConsumptionDataLoading } = useMutation(getSiteSpecificConsumptionData);
  const { mutate: getConsumptionOnMonthly, isLoading: getConsumptionOnMonthlyLoading } = useMutation(getConsumptionsOnMonth);
  const { mutate: getConsumptionSite, isLoading: getConsumptionSiteLoading } = useMutation(getConsumptionsBySite);
  const { mutate: getConsumptionYear, isLoading: getConsumptionYearLoading } = useMutation(getConsumptionsYearly);
  const { mutate: getConsumptionRegion, isLoading: getConsumptionRegionLoading } = useMutation(getConsumptionsByRegion);
  const { mutate: getConsumptionFacilityType, isLoading: getConsumptionFacilityTypeLoading } = useMutation(getConsumptionsByFacilityType);
  const { mutate: getConsumptionByCountries, isLoading: getConsumptionByCountriesLoading } = useMutation(getCountriesConsumptionData);

  
  const consumptionLoadingStates = useMemo(() => ({
    getSSConsumptionDataLoading,
    getConsumptionOnMonthlyLoading,
    getConsumptionSiteLoading,
    getConsumptionYearLoading,
    getConsumptionRegionLoading,
    getConsumptionFacilityTypeLoading,
    getConsumptionByCountriesLoading,
  }), [
    getSSConsumptionDataLoading,
    getConsumptionOnMonthlyLoading,
    getConsumptionSiteLoading,
    getConsumptionYearLoading,
    getConsumptionRegionLoading,
    getConsumptionFacilityTypeLoading,
    getConsumptionByCountriesLoading,
  ]);


  // other
  const { mutate: getScopeOnMonthly, isLoading: getScopeOnMonthlyLoading } = useMutation(getScopeOnMonth);
  const { mutate: getSSpendAccData, isLoading: getSSpendAccDataLoading } = useMutation(getSpendAccData);



  const makeFilterApiCalls = (body: EmissionsOnMonthBody) => {

    // Emission API calls
    if (selectedBtnOption === "ghc-emission") {
      console.log("ghc-emission ::::::")
      getEmissionsOnMonthly(body, {
        onSuccess: (data) => {
          const filterData: any = filterDataWithFiscalYear(
            data,
            yearValue === null
              ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
              : yearValue,
            startMonth ? startMonth : "Jan",
            endMonth ? endMonth : "Dec",
          );
          setMonthlyData(filterData);
        },
        onError: () => {
          // console.log("failed",err)
        },
      });
  
      getEstimatesOnMonthly(body, {
        onSuccess: (data) => {
          const filterData: any = filterDataWithFiscalYear(
            data,
            yearValue === null
              ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
              : yearValue,
            startMonth ? startMonth : "Jan",
            endMonth ? endMonth : "Dec",
          );
          setEstimatesMonthlyData(filterData);
        },
        onError: () => {
          // console.log("failed",err)
        },
      });
  
      getEstimatesSite(body, {
        onSuccess: (data) => {
          const filterData: any = filterDataWithFiscalYear(
            data,
            yearValue === null
              ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
              : yearValue,
            startMonth ? startMonth : "Jan",
            endMonth ? endMonth : "Dec",
          );
          setEstimatesBySiteData(filterData);
        },
        onError: () => {
          // console.log("failed",err)
        },
      });
  
      getEmissionsSite(body, {
        onSuccess: (data) => {
          setSitesData(data);
        },
        onError: () => { },
      });
  
      getEmissionsScope(body, {
        onSuccess: (data) => {
          setScopesResp(data);
        },
        onError: () => { },
      });
  
      getEmissionsUtility(body, {
        onSuccess: (data) => {
          setUtilityData(data);
        },
        onError: () => { },
      });
  
      getEmissionsYear(body, {
        onSuccess: (data) => {
          setYearlyData(data);
        },
        onError: () => { },
      });
  
      getEmissionsScopeYear(body, {
        onSuccess: (data) => {
          setYearlyScopeData(data);
        },
        onError: () => { },
      });
  
      getEmissionsRegion(body, {
        onSuccess: (data) => {
          const filterData: any = filterDataWithFiscalYear(
            data,
            yearValue === null
              ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
              : yearValue,
            startMonth ? startMonth : "Jan",
            endMonth ? endMonth : "Dec",
          );
          setRegionsData(filterData);
        },
        onError: () => { },
      });
  
      getEmissionsFacilityType(body, {
        onSuccess: (data) => {
          setFacilityTypesData(data);
        },
        onError: () => { },
      });
  
      getEmissionsByCountries(body, {
        onSuccess: (data) => {
          setCountriesResp(data);
        },
        onError: () => { },
      });
  
      getSSEmissionsData(body, {
        onSuccess: (data) => {
          setSiteSpecificAccData(data);
        },
        onError: () => { },
      });
    }

    // Consumption API Calls
    if (selectedBtnOption === "consumption") {
      console.log("consumption ::::::")
      getSSConsumptionData(body, {
        onSuccess: (data) => {
          setSiteSpecificConsumptionData(data);
        },
        onError: () => { },
      });
  
      getSSpendAccData(body, {
        onSuccess: (data) => {
          setSiteSpendAccData(data);
        },
        onError: () => { },
      });
  
      getConsumptionOnMonthly(body, {
        onSuccess: (data) => {
          const filterData: any = filterDataWithFiscalYear(
            data,
            yearValue === null
              ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
              : yearValue,
            startMonth ? startMonth : "Jan",
            endMonth ? endMonth : "Dec",
          );
          setConsumptionMonthlyData(filterData);
        },
        onError: () => {
          // console.log("failed",err)
        },
      });
  
      getConsumptionSite(body, {
        onSuccess: (data) => {
          setConsumptionSitesData(data);
        },
        onError: () => { },
      });
  
      getConsumptionYear(body, {
        onSuccess: (data) => {
          setConsumptionYearlyData(data);
        },
        onError: () => { },
      });
  
      getConsumptionRegion(body, {
        onSuccess: (data) => {
          setConsumptionRegionsData(data);
        },
        onError: () => { },
      });
  
      getConsumptionFacilityType(body, {
        onSuccess: (data) => {
          setConsumptionFacilityTypesData(data);
        },
        onError: () => { },
      });
  
      getConsumptionByCountries(body, {
        onSuccess: (data) => {
          setConsumptionCountriesResp(data);
        },
        onError: () => { },
      });
    }

    // Financial API call
    if (selectedBtnOption === "financial") {
      console.log("financial ::::::")

      getFinancialsOnMonthly(body, {
        onSuccess: (data) => {
          const filterData: any = filterDataWithFiscalYear(
            data,
            yearValue === null
              ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
              : yearValue,
            startMonth ? startMonth : "Jan",
            endMonth ? endMonth : "Dec",
          );
          setMonthlyFinancialData(filterData);
        },
        onError: () => { },
      });
  
      getFinancialsSite(body, {
        onSuccess: (data) => {
          setSitesFinancialData(data);
        },
        onError: () => { },
      });
  
      getFinancialsUtility(body, {
        onSuccess: (data) => {
          setUtilityFinancialData(data);
        },
        onError: () => { },
      });
  
      getFinancialsYear(body, {
        onSuccess: (data) => {
          setYearlyFinancialData(data);
        },
        onError: () => { },
      });
  
      getFinancialsRegion(body, {
        onSuccess: (data) => {
          setRegionsFinancialData(data);
        },
        onError: () => { },
      });
  
      getFinancialsFacilityType(body, {
        onSuccess: (data) => {
          setFacilityTypesFinancialData(data);
        },
        onError: () => { },
      });
  
      getFinancialsByCountries(body, {
        onSuccess: (data) => {
          setCountriesFinancialResp(data);
        },
        onError: () => { },
      });
  
      getFinancialEMSpend(body, {
        onSuccess: (data) => {
          const filterData: any = filterDataWithFiscalYear(
            data,
            yearValue === null
              ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
              : yearValue,
            startMonth ? startMonth : "Jan",
            endMonth ? endMonth : "Dec",
          );
          setFinancialEMSpend(filterData);
        },
        onError: () => { },
      });
    }
    
    // Other API calls
    getScopeOnMonthly(body, {
      onSuccess: (data) => {
        const filterData: any = filterDataWithFiscalYear(
          data,
          yearValue === null
            ? (parseInt(fiscalYear.replace("FY", ""), 10) as number)
            : yearValue,
          startMonth ? startMonth : "Jan",
          endMonth ? endMonth : "Dec",
        );
        setMonthlyScopeData(filterData);
      },
      onError: () => {
        // console.log("failed",err)
      },
    });

    getUnitLevelCost(body, {
      onSuccess: (data) => {
        setUnitLevelCost(data);
      },
      onError: () => { },
    });
  };

  
  useEffect(() => {
    if (
      isSitesLoading ||
      isAccountNumbersLoading ||
      isCountriesLoading ||
      isCountryRegionsLoading ||
      isFacilityTypesLoading ||
      isScopesLoading ||
      isUtilityTypesLoading ||
      isMeterNumbersLoading
    ) {
      return;
    }

    let meterNum;
    let accNum;
    let utilityType;
    let siteid;
    let counReg;
    let country;
    let facilityType;
    let scope;

    if (sitesOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.SITE_IDS,
      );

      if (selectedOptionsFromSearchParams.length) {
        siteid = sitesOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.value);
      }
    }
    if (accountNumbersOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.ACCOUNT_NUMBERS,
      );

      if (selectedOptionsFromSearchParams.length) {
        accNum = accountNumbersOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.label);
      }
    }
    if (meterNumbersOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.METER_NUMBERS,
      );

      if (selectedOptionsFromSearchParams.length) {
        meterNum = meterNumbersOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.label);
      }
    }
    if (utilityTypesOptions) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.UTILITY_TYPE_IDS,
      );

      if (selectedOptionsFromSearchParams.length) {
        utilityType = utilityTypesOptions
          .filter(({ value }) =>
            selectedOptionsFromSearchParams.includes(`${value}`),
          )
          .map((option) => option.value);
      }
    }

    if (countryRegionsList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.COUNTRY_REGION,
      );

      if (selectedOptionsFromSearchParams.length) {
        counReg = countryRegionsList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }

    if (countriesList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.COUNTRIES,
      );

      if (selectedOptionsFromSearchParams.length) {
        country = countriesList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }

    if (scopesList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.SCOPE,
      );

      if (selectedOptionsFromSearchParams.length) {
        scope = scopesList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }

    if (facilityTypesList) {
      const selectedOptionsFromSearchParams = searchParams.getAll(
        QUERY_PARAMS_KEYS.SITE_TYPES,
      );

      if (selectedOptionsFromSearchParams.length) {
        facilityType = facilityTypesList.find((value) =>
          selectedOptionsFromSearchParams.includes(value),
        );
      }
    }

    const body: EmissionsOnMonthBody = {
      countryRegion: counReg || "", // || "AMER",
      countries: country ? [country] : null,
      siteIds: siteid && siteid.length ? siteid : null,
      facilityTypes: facilityType ? [facilityType] : null,
      scope: scope || "",
      utilityTypeIds: utilityType && utilityType?.length ? utilityType : null,
      accountNumbers: accNum && accNum.length ? accNum : null,
      meterNumbers: meterNum && meterNum.length ? meterNum : null,
    };
      makeFilterApiCalls(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedBtnOption,
  ]);

  const onClearFiltersClick = () => {
    setSelectedCountryRegion("");
    setSelectedCountry("");
    setSelectedFalityTypeOption("");
    setSelectedScope("");
    setSelectedUtilityTypes([]);
    setSelectedSites([]);
    setSelectedAccountNumbers([]);
    setSelectedMeterNumbers([]);
    setFiscalYear(currentFiscalYear);
    getData();
  };

  useEffect(() => {
    setTimeout(() => {
      onClearFiltersClick();
    }, 2000)
  }, []);


  const getData = () => {
    const body: EmissionsOnMonthBody = {
      countryRegion: "",
      countries: null,
      siteIds: null,
      facilityTypes: null,
      scope: "",
      utilityTypeIds: null,
      accountNumbers: null,
      meterNumbers: null,
    };
    makeFilterApiCalls(body);
    const newUrl = DASHBOARD_PAGE.ABSOLUTE_PATH;
    window.history.pushState({}, "", newUrl);
  };
  
  const onApplyFiltersClick = () => {
    const body: EmissionsOnMonthBody = {
      countryRegion: selectedCountryRegion, // || "AMER",
      countries: selectedCountry ? [selectedCountry] : null,
      siteIds: selectedSites.length ? selectedSites.map((e) => e.value) : null,
      facilityTypes: selectedFacilityType ? [selectedFacilityType] : null,
      scope: selectedScope || "",
      utilityTypeIds: selectedUtilityTypes.length
        ? selectedUtilityTypes.map((e) => e.value)
        : null,
      accountNumbers: selectedAccountNumbers.length
        ? selectedAccountNumbers.map((e) => e.label)
        : null,
      meterNumbers: selectedMeterNumbers.length
        ? selectedMeterNumbers.map((e) => e.label)
        : null,
    };
    makeFilterApiCalls(body);
  };

  const tabs = {
    [GHC_EMISSIONS]: [
      {
        label: "Overall Emissions",
        content: useMemo(() => (
          <OverallEmissionsComponent
            emissionLoadingStates={emissionLoadingStates}
            estimatesBySiteData={estimatesBySiteData}
            estimatesMonthlyData={estimatesMonthlyData}
            monthlyScopeData={monthlyScopeData}
            monthlyData={monthlyData}
            sitesData={sitesData}
            yearlyData={yearlyData}
            yearlyScopeData={yearlyScopeData}
            regionsData={regionsData}
            utilityData={utilityData}
            scopesResp={scopesResp}
            facilityTypesData={facilityTypesData}
            countriesResp={countriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            utilityTypesOptions={utilityTypesOptions}
            monthEmYears={monthEmYears}
            regionEmYears={regionEmYears}
            monthEmissionsYear={monthEmissionsYear}
            setMonthEmissionsYear={setMonthEmissionsYear}
            regionEmissionsYear={regionEmissionsYear}
            setRegionEmissionsYear={setRegionEmissionsYear}
            scopeYear={scopeYear}
            setScopeYear={setScopeYear}
            utYear={utYear}
            setUTYear={setUTYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ), [
          estimatesBySiteData, estimatesMonthlyData, monthlyScopeData, monthlyData, sitesData,
          yearlyData, yearlyScopeData, regionsData, utilityData, scopesResp, facilityTypesData,
          countriesResp, sitesOptions, countryRegionsList, facilityTypesList, utilityTypesOptions,
          monthEmYears, regionEmYears, monthEmissionsYear, regionEmissionsYear, setMonthEmissionsYear,
          setRegionEmissionsYear, scopeYear, setScopeYear, utYear, setUTYear, startMonth, endMonth, yearValue,
        ]),
      },
    ],
    [CONSUMPTION]: [
      {
        label: "Electricity",
        content: useMemo(() => (
          <ConsumptionElectricityComponent
            consumptionLoadingStates={consumptionLoadingStates}
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthCSElectricityYear}
            setMonthConsumptionYear={setMonthCSElectricityYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ), [
          consumptionMonthlyData, consumptionSitesData, consumptionYearlyData, consumptionRegionsData,
          consumptionFacilityTypesData, consumptionCountriesResp, sitesOptions, countryRegionsList,
          facilityTypesList, monthCSElectricityYear, setMonthCSElectricityYear, startMonth, endMonth, yearValue,
        ]),
      },
      {
        label: "Natural Gas",
        content: useMemo(() => (
          <ConsumptionNaturalGasComponent
            consumptionLoadingStates={consumptionLoadingStates}
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthConsumptionYear}
            setMonthConsumptionYear={setMonthConsumptionYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ), [
          consumptionMonthlyData, consumptionSitesData, consumptionYearlyData, consumptionRegionsData,
          consumptionFacilityTypesData, consumptionCountriesResp, sitesOptions, countryRegionsList,
          facilityTypesList, monthConsumptionYear, setMonthConsumptionYear, startMonth, endMonth, yearValue,
        ]),
      },
      {
        label: "Water",
        content: useMemo(() => (
          <ConsumptionWaterComponent
            consumptionLoadingStates={consumptionLoadingStates}
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthCSWaterYear}
            setMonthConsumptionYear={setMonthCSWaterYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ), [
          consumptionMonthlyData, consumptionSitesData, consumptionYearlyData, consumptionRegionsData,
          consumptionFacilityTypesData, consumptionCountriesResp, sitesOptions, countryRegionsList,
          facilityTypesList, monthCSWaterYear, setMonthConsumptionYear, startMonth, endMonth, yearValue,
        ]),
      },
      {
        label: "Sewer",
        content: useMemo(() => (
          <ConsumptionSewerComponent
            consumptionLoadingStates={consumptionLoadingStates}
            monthlyData={consumptionMonthlyData}
            sitesData={consumptionSitesData}
            yearlyData={consumptionYearlyData}
            regionsData={consumptionRegionsData}
            facilityTypesData={consumptionFacilityTypesData}
            countriesResp={consumptionCountriesResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            monthConsumptionYear={monthCSSewerYear}
            setMonthConsumptionYear={setMonthCSSewerYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ), [
          consumptionMonthlyData, consumptionSitesData, consumptionYearlyData, consumptionRegionsData,
          consumptionFacilityTypesData, consumptionCountriesResp, sitesOptions, countryRegionsList,
          facilityTypesList, monthCSSewerYear, setMonthConsumptionYear, startMonth, endMonth, yearValue,
        ]),
      },
    ],
    [FINANCIAL]: [
      {
        label: "Overall Financials",
        content: useMemo(() => (
          <OverallFinancialsComponent
            financialLoadingStates={financialLoadingStates}
            monthlyData={monthlyFinancialData}
            sitesData={sitesFinancialData}
            yearlyData={yearlyFinancialData}
            regionsData={regionsFinancialData}
            utilityData={utilityFinancialData}
            facilityTypesData={facilityTypesFinancialData}
            unitLevelCostData={unitLevelCost}
            EMSpendData={financialEMSpend}
            countriesResp={countriesFinancialResp}
            sitesOptions={sitesOptions}
            countryRegionsList={countryRegionsList}
            facilityTypesList={facilityTypesList}
            utilityTypesOptions={utilityTypesOptions}
            monthFinancialYear={monthFinancialYear}
            setMonthFinancialYear={setMonthFinancialYear}
            financialCarbonYear={financialCarbonYear}
            setFinancialCarbonYear={setFinancialCarbonYear}
            startMonth={startMonth ? startMonth : " Jan"}
            endMonth={endMonth ? endMonth : "Dec"}
            selectedYear={yearValue}
          />
        ), [
          monthlyFinancialData, sitesFinancialData, yearlyFinancialData, regionsFinancialData,
          utilityFinancialData, facilityTypesFinancialData, unitLevelCost, financialEMSpend,
          countriesFinancialResp, sitesOptions, countryRegionsList, facilityTypesList, utilityTypesOptions,
          monthFinancialYear, setMonthFinancialYear, financialCarbonYear, setFinancialCarbonYear,
          startMonth, endMonth, yearValue,
        ]),
      },
    ],
    [SITE_SPECIFIC]: [
      {
        label: "Emissions",
        content: useMemo(() => (
          <SiteSpecificEmissions
            siteSpecificAccData={siteSpecificAccData}
            siteSpendAccData={siteSpendAccData}
            siteCostYear={siteCostYear}
            siteCostYearsList={siteCostYearsList}
            setSiteCostYear={setSiteCostYear}
            siteEMYearsList={siteEMYearsList}
            siteEmYear={siteEmYear}
            setSiteEMyear={setSiteEMyear}
          />
        ), [
          siteSpecificAccData, siteSpendAccData, siteCostYear, siteCostYearsList,
          setSiteCostYear, siteEMYearsList, siteEmYear, setSiteEMyear,
        ]),
      },
      {
        label: "Consumption",
        content: useMemo(() => (
          <SiteSpecificConsumption
            siteSpecificConsumptionData={siteSpecificConsumptionData}
            setSiteElecYear={setSiteElecYear}
            siteElecYear={siteElecYear}
            siteElecYearsList={siteElecYearsList}
            setSiteNGYear={setSiteNGYear}
            siteNGYear={siteNGYear}
            siteNGYearsList={siteNGYearsList}
          />
        ), [
          siteSpecificConsumptionData, setSiteElecYear, siteElecYear, siteElecYearsList,
          setSiteNGYear, siteNGYear, siteNGYearsList,
        ]),
      },
    ],
  };

  const btnStyles = {
    fontSize: "1rem",
    color: "black",
    backgroundColor: "transparent",
  };

  const onStackButtonClick = useCallback(
    debounce((e: React.MouseEvent<HTMLDivElement>) => {
      const key = (e?.target as HTMLElement)?.dataset?.key as string;
      if (key) {
        setSelectedBtnOption(key);
      }
    }, 300), // Adjust the debounce delay as needed (e.g., 300ms)
    []
  );

  const renderTabButtons = () => (
    <Stack
      direction="row"
      alignItems="center"
      spacing={3}
      marginLeft="20px"
      padding="24px 6px"
      onClick={onStackButtonClick}
    >
      {/* GHG Emission */}
      <Button
        sx={{
          ...btnStyles,
          backgroundColor:
            selectedBtnOption === GHC_EMISSIONS ? COLORS.green : "transparent",
        }}
        variant="outlined"
        data-key={GHC_EMISSIONS}
      >
        GHG Emissions
      </Button>
      {/* Consumption */}
      <Button
        data-key="consumption"
        sx={{
          ...btnStyles,
          backgroundColor:
            selectedBtnOption === CONSUMPTION ? COLORS.green : "transparent",
        }}
        variant="outlined"
      >
        Consumption
      </Button>
      {/* Financials */}
      <Button
        data-key="financial"
        sx={{
          ...btnStyles,
          backgroundColor:
            selectedBtnOption === FINANCIAL ? COLORS.green : "transparent",
        }}
        variant="outlined"
      >
        Financials
      </Button>
    </Stack>
  );

  const renderFields = () => {
    const disableApplyFiltersBtn =
      isSitesLoading ||
      isAccountNumbersLoading ||
      isCountriesLoading ||
      isCountryRegionsLoading ||
      isFacilityTypesLoading ||
      isScopesLoading ||
      isUtilityTypesLoading ||
      isMeterNumbersLoading;
    return (
      <Box
        my={3}
        mx={3}
        sx={{
          marginRight: "0px",
        }}
      >
        <Box
          sx={{
            ...FILTERS_AND_ACTIONS_WRAPPER,
            marginRight: "0px",
          }}
        >
          <Box
            sx={{
              ...FLEX_ALIGN_CENTER_GAP_2,
              alignItems: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <Box sx={MULTISELECT_WRAPPER}>
              {fiscalYears && <FormControl sx={{ minWidth: 220 }}>
                <SelectControlledField
                  label="Fiscal Year"
                  options={fiscalYears}
                  selectedValues={fiscalYear}
                  setSelectedValues={setFiscalYear}
                />
              </FormControl>}
            </Box>
            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isCountryRegionsLoading}
                label="Region"
                options={countryRegionsList}
                selectedValues={selectedCountryRegion}
                setSelectedValues={setSelectedCountryRegion}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isCountriesLoading}
                label="Country"
                options={countriesList}
                selectedValues={selectedCountry}
                setSelectedValues={setSelectedCountry}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isFacilityTypesLoading}
                label="Facility type"
                options={facilityTypesList}
                selectedValues={selectedFacilityType}
                setSelectedValues={setSelectedFalityTypeOption}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <SelectControlledField
                disabled={isScopesLoading}
                label="Scope"
                options={filterScopeList}
                selectedValues={selectedScope}
                setSelectedValues={setSelectedScope}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <AutocompleteField
                textFieldProps={{
                  label: "Utility type",
                  disabled: isUtilityTypesLoading,
                  placeholder:
                    selectedUtilityTypes.length === 0
                      ? "all selected"
                      : undefined,
                }}
                autocompleteProps={{
                  disabled: isUtilityTypesLoading,
                  multiple: true,
                  value: selectedUtilityTypes,
                  disableClearable: true,
                  blurOnSelect: false,
                  disableCloseOnSelect: true,
                  onChange: (e, data) =>
                    setSelectedUtilityTypes(
                      data as MultiSelectControlledOption[],
                    ),
                  options:
                    selectedBtnOption === GHC_EMISSIONS
                      ? utitlityTypesForEmissions
                      : selectedBtnOption === CONSUMPTION
                        ? utitlityTypesForConsumption
                        : selectedBtnOption === FINANCIAL
                          ? utitlityTypesForFinancial
                          : filterTypes,
                  getOptionLabel: (option) =>
                    (option as MultiSelectControlledOption).label,
                  isOptionEqualToValue: (opt, val) =>
                    (opt as MultiSelectControlledOption).value ===
                    (val as MultiSelectControlledOption).value,

                  renderOption: (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={checkboxIcon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {(option as MultiSelectControlledOption).label}
                    </li>
                  ),
                  renderTags: (value) => (
                    <Box sx={{ ml: 1 }} component="span">
                      <>
                        {value.length === 1
                          ? (value[0] as MultiSelectControlledOption).label
                          : value.length === utilityTypesOptions.length
                            ? "all selected"
                            : `${value.length} selected`}
                      </>
                    </Box>
                  ),
                }}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <AutocompleteField
                textFieldProps={{
                  label: "Sites",
                  disabled: isSitesLoading,
                  placeholder:
                    selectedSites.length === 0 ? "all selected" : undefined,
                }}
                autocompleteProps={{
                  disabled: isSitesLoading,
                  multiple: true,
                  value: selectedSites,
                  disableClearable: true,
                  blurOnSelect: false,
                  disableCloseOnSelect: true,
                  onChange: (e, data) =>
                    setSelectedSites(data as MultiSelectControlledOption[]),
                  options: sitesOptions,
                  getOptionLabel: (option) =>
                    (option as MultiSelectControlledOption).label,
                  isOptionEqualToValue: (opt, val) =>
                    (opt as MultiSelectControlledOption).value ===
                    (val as MultiSelectControlledOption).value,

                  renderOption: (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={checkboxIcon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {(option as MultiSelectControlledOption).label}
                    </li>
                  ),
                  renderTags: (value) => (
                    <Box sx={{ ml: 1 }} component="span">
                      <>
                        {value.length === 1
                          ? (value[0] as MultiSelectControlledOption).label
                          : value.length === sitesOptions.length
                            ? "all selected"
                            : `${value.length} selected`}
                      </>
                    </Box>
                  ),
                }}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <MultiselectControlledField
                disabled={isAccountNumbersLoading}
                isAllSelectedOnEmptySelection
                label="Account number"
                options={filterAccountNumbers}
                selectedValues={selectedAccountNumbers}
                setSelectedValues={setSelectedAccountNumbers}
              />
            </Box>

            <Box sx={MULTISELECT_WRAPPER}>
              <MultiselectControlledField
                disabled={isMeterNumbersLoading}
                isAllSelectedOnEmptySelection
                label="Meter number"
                options={filterMeterNumbers}
                selectedValues={selectedMeterNumbers}
                setSelectedValues={setSelectedMeterNumbers}
              />
            </Box>
          </Box>

          <Box
            sx={{
              ...FLEX_ALIGN_CENTER_GAP_2,
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button onClick={onClearFiltersClick} variant="outlined">
              Clear Filters
            </Button>
            <Button
              onClick={onApplyFiltersClick}
              disabled={disableApplyFiltersBtn}
              variant="contained"
            >
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <PageDiv
      sx={{
        ".highcharts-credits": { display: "none" },
      }}
    >
      {renderTabButtons()}
      {renderFields()}
      <DashboardTabs
        tabs={tabs[selectedBtnOption]}
        selectedBtnOption={selectedBtnOption}
      />
    </PageDiv>
  );
};
